import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import { BrowserRouter as Router } from "react-router-dom";
import { promo_urls_pattrens, admin_urls_pattrens, creator_urls_pattrens } from "./urls";
import MainBody from "./MainBody";
import { connect } from "react-redux";
import Login from "./Pages/User Pannel/authentication/Login";
import Signup from "./Pages/User Pannel/authentication/Signup";
import { useEffect } from "react";
import { authCheckState, authLogout, authSuccess } from "./store/actions/actions";
import TermsofServices from "./Pages/User Pannel/Terms/TermsofServices";
import PromoTermsofServices from "./Pages/User Pannel/Terms/PromoTermsofServices.js";
import '@fontsource/poppins';
import AppearanceRelease from "./Pages/User Pannel/Terms/ApperanceRelease.js";
import PrivacyPolicy from "./Pages/User Pannel/Terms/PrivacyPolicy.js";
import { toast } from "react-toastify";

let theme = createTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});
theme = responsiveFontSizes(theme);

const RenderComponents = ({ authSuccess, authLogout }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const _authSuccess = searchParams.get("authSuccess");
    if (_authSuccess && _authSuccess === "1") {
      const access = searchParams.get("access");
      const refresh = searchParams.get("refresh");

      if (access && refresh) {
        authLogout();
        authSuccess({ access: access, refresh: refresh });
        toast.success("Account created successfuly");
        navigate(location.pathname, { replace: true });
      }
    }
  }, [location.search])
  return <></>
}
function App(props) {
  useEffect(() => {
    props.authCheckState();

    // eslint-disable-next-line
  });
  const urls = props.is_promouser ? promo_urls_pattrens : (props.is_admin ? admin_urls_pattrens : creator_urls_pattrens);
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <RenderComponents authSuccess={props.authSuccess} authLogout={props.authLogout} />
        {props.authenticated ? (
          <MainBody>
            <Routes>
              {urls.map((url, index) =>
                !props.is_admin && url.admin_only ? (
                  <Route key={url}></Route>
                ) : (
                  <Route key={url} exact path={url.url} element={url.element} />
                )
              )}
            </Routes>
          </MainBody>
        ) : (
          <Routes>
            <Route path={"/creator_signup"} element={<Signup />} />
            <Route path={"/partner_signup169"} exact element={<Signup />} />
            <Route path={"/terms/privacypolicy"} exact element={<PrivacyPolicy />} />
            <Route path={"/terms/appereance"} exact element={<AppearanceRelease />} />
            <Route path={"/terms"} exact element={<TermsofServices />} />
            <Route path={"/promo_terms"} exact element={<PromoTermsofServices />} />
            <Route path={"/"} element={<Login />} />
            {/* <Route path="*" element={<Navigate to="/" />} /> */}
          </Routes>
        )}
      </Router>
    </ThemeProvider>
  );
}
const mapStatesToProps = (state) => {
  return {
    authenticated: state.user !== null,
    is_admin: state.user?.is_staff,
    is_creator: state.user?.creator,
    is_promouser: state.user?.is_promouser || false,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    authCheckState: () => dispatch(authCheckState()),
    authSuccess: (data) => dispatch(authSuccess(data)),
    authLogout: () => dispatch(authLogout()),
  };
};
export default connect(mapStatesToProps, mapDispatchToProps)(App);
