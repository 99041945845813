import { Box, Container, Typography } from "@mui/material";
import React from "react";

export default function PrivacyPolicy() {
    return (
        <Container maxWidth="md" sx={{
            textAlign: "justify"
        }}>
            <Box>
                <Typography variant="h5" align="center" my={3}>
                    <Box component="span" sx={{ color: "primary.main" }}> CreatorVault </Box> PRIVACY POLICY
                </Typography>
                <Typography variant="body2">This WooGlobe website ("Website") is operated by CreatorVault Limited whose registered address is:</Typography>

                <Typography variant="body2">WooGlobe Limited</Typography>

                <Typography variant="body2">9 Barn End Drive</Typography>

                <Typography variant="body2">Wilmington, DA2 7BX</Typography>

                <Typography variant="body2" paragraph>United Kingdom</Typography>

                <Typography variant="body2">
                    The terms "WooGlobe", "we", "us", "our" and "ours" when used in this Privacy Policy mean WooGlobe Ltd which includes all entities under the control of WooGlobe Ltd.
                    The terms "you", "your" and "yours" when used in this Privacy Policy mean any user of this Website.
                </Typography>

                <Typography variant="subtitle1" mt={3.75} mb={1.875} fontWeight={700}>
                    Introduction to Privacy Policy
                </Typography>
                <Typography variant="body1">
                    This Privacy Policy describes our current policies and practices on personal
                    data collected by us from you directly and/or through the Website. The term
                    "personal data" refers to personally identifiable information about you; this
                    can include your name, birthday, e-mail address, certain location data or
                    mailing address, job description, and health-related data.
                    <br /><br />
                    Please note that by submitting personal data manually or in electronic form to
                    us and/or by using our Website you give your consent that all personal data on
                    each occasion that you submit may be processed by WooGlobe, in the manner and
                    for the purposes described in this Privacy Policy.
                    <br /><br />
                    You may be accessing our Website from a computer or mobile phone device (through
                    an iPhone application, for example) and this policy governs your use of our
                    Website regardless of the means of access. We also like to interact with you on
                    third party sites where we post content or invite your feedback, such as
                    www.facebook.com/WooGlobe and www.twitter.com/WooGlobe ("Third Party Sites").
                    <br /><br />
                    Please note, this website is not intended for children and we do not knowingly
                    collect data relating to children. By submitting personal data to us, you
                    explicitly consent to its processing in accordance with this policy. You can
                    withdraw your consent at any time by contacting us.
                </Typography>

                <Typography variant="subtitle1" mt={3.75} mb={1.875} fontWeight={700}>
                    Notification of changes to this Privacy Policy
                </Typography>
                <Typography variant="body1">
                    We are continually improving our means and channels of communication, and adding
                    new functionality and features to this website and to our existing services.
                    Because of these ongoing changes, changes in the law and the changing nature of
                    technology, our data practices will change from time to time. If and when our
                    data practices change, we will notify you of the changes via this page. We
                    encourage you to check this Privacy Policy frequently.
                </Typography>

                <Typography variant="subtitle1" mt={3.75} mb={1.875} fontWeight={700}>
                    Collection of personal data
                </Typography>
                <Typography variant="body1">
                    You may provide us with your personal data so that you can receive information,
                    advice, or services from us. We process personal data based on consent, contract
                    fulfilment, or legitimate interests. We do not knowingly collect data from
                    children under 16 without parental consent.
                    <br /><br />
                    <b>Identity Data</b> (to set up your account) includes username, email address and
                    password to set up your account. There is the option to add your telephone number
                    and physical address once you have set up your account.
                    <br /><br />
                    <b>Financial Data</b> includes Paypal email address and may include bank account
                    or payment card details.
                    <br /><br />
                    <b>Technical Data</b> includes internet protocol (IP) address, your login data,
                    browser type and version, time zone setting and location, browser plug-in types
                    and versions, operating system and platform and other technology on the devices
                    you use to access this website.
                    <br /><br />
                    <b>Usage Data</b> includes information about how you use our website, products,
                    and services, which may be gathered via cookies on our website.
                    <br /><br />
                    <b>Communications Data</b> includes your preferences in receiving communications
                    from us and your communication preferences.
                    <br /><br />
                    If you register with us, as part of the registration process, you will share some
                    personal information, such as your name, address, and e-mail address. You do not
                    have to be a registered user to visit the Website. But, you do have to register if
                    you want to upload videos, associated data, interact with the Website or make full
                    use of our services. Once you register, you will have an account with WooGlobe and
                    a password to access that account. You may never use another person's account
                    without permission, and you may not use anyone else's password.
                    <br /><br />
                    Where we need to collect personal data by law, or under the terms of a contract we
                    have with you and you fail to provide that data when requested, we may not be able
                    to perform the contract we have or are trying to enter into with you (for example,
                    to provide you with goods or services). In this case, we may have to cancel a product
                    or service you have with us but we will notify you if this is the case at the time.
                    <br /><br />
                    You should keep your registration details and all other information provided to us
                    current and complete. You are responsible for maintaining the confidentiality of your
                    account and password. Please also let us know immediately if there is any unauthorized
                    use of your account or password. We recommend that you always log out from your account
                    at the end of a session with us to prevent other people from accessing your account
                    without your permission. You represent and warrant that all the information you provide
                    in your public profile and user account is accurate and complete. You are solely
                    responsible for any activity occurring on your user account.
                    <br /><br />
                    We sometimes supplement the information that you provide with information that is
                    received from third parties. For instance, if inaccurate post codes are received,
                    we may verify your location using your IP address.
                    <br /><br />
                    We also reserve the right to monitor and/or record your communication with us by
                    mail, voice, email, or any other form of transmission for the purposes of quality
                    control, security, and other business needs. Any personal data contained in such
                    recorded information will be processed in accordance with the terms of this
                    Privacy Policy.
                    <br /><br />
                    We may also receive your personal data from other members of WooGlobe, our service
                    providers, agents, advisers, or business partners, including, without limitation,
                    news agencies. Where personal data is disclosed to us by third parties, it is the
                    obligation of the disclosing party to ensure such personal data is disclosed with
                    requisite authority and consent, and we will process such personal data in
                    accordance with this Privacy Policy.
                </Typography>

                <Typography variant="subtitle1" mt={3.75} mb={1.875} fontWeight={700}>
                    Your public profile
                </Typography>
                <Typography variant="body1">
                    Once you become a registered user with us, you may provide additional
                    information to your WooGlobe profile describing your skills, experiences,
                    background, and other information. Providing additional information about you,
                    beyond what is minimally required at registration, is entirely up to you.
                    Any information you provide at registration or in your profile section may be
                    used by us as described in our Terms of Service and this Privacy Policy.
                    You can review the personal information you have provided to us and make any
                    desired changes to the information that you publish, or to the settings for your
                    account, including your email and contact preferences, at any time by logging
                    into your account on the Website. Please be aware that even after your request
                    for a change is processed, we may, for a reasonable time, retain residual
                    information about you in the backup and/or archival copies of our database.
                </Typography>

                <Typography variant="subtitle1" mt={3.75} mb={1.875} fontWeight={700}>
                    Use of personal data
                </Typography>
                <Typography variant="body1">
                    We process your personal data, and share your personal data with others,
                    only for specific and limited purposes when the law allows. We ask only
                    for data that is adequate, relevant and not excessive for those purposes.
                    Some of these purposes may include the following:
                </Typography>
                <br />
                <br />
                <ul>
                    <li>
                        <Typography variant="body1">
                            For the purposes of managing your account and content that you upload or
                            licence from the site
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            To process your request for information or advice
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            To help diagnose any problems with our server and administer our website
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            Where we need to perform the contract we are about to enter into or have
                            entered into with you, including when we mainly need to contact you to
                            help editorialise a story better
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            To verify information and content that you have provided to us
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            Where it is necessary for our legitimate interests (or those of a third party)
                            and your interests and fundamental rights do not override those interests
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            Where we need to comply with a legal or regulatory obligation
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            To process your payments, which may include your postal and e-mail addresses.
                            We may also receive the billing and payment information that you provide
                            when your purchase is processed by another party, such as Paypal.
                            Our Terms of Service explain our policies and terms relevant to our charges
                            and billing practices
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            You will receive marketing communications from us if you provided us with
                            your details when you registered for that mailing list and, in each case
                            when you have opted in to receiving that marketing
                        </Typography>
                    </li>
                </ul>
                <Typography variant="body1">
                    We will only use your personal data for the purposes for which we collected it,
                    unless we reasonably consider that we need to use it for another reason and
                    that reason is compatible with the original purpose. If you wish to get an
                    explanation as to how the processing for the new purpose is compatible with
                    the original purpose, please contact us.
                    <br />
                    <br />
                    If we need to use your personal data for an unrelated purpose, we will notify
                    you and we will explain the legal basis, which allows us to do so.
                    <br />
                    <br />
                    Please note that we may process your personal data without your knowledge or
                    consent, in compliance with the above rules, where this is required or permitted by law.
                </Typography>

                <Typography variant="subtitle1" mt={3.75} mb={1.875} fontWeight={700}>
                    Data Retention
                </Typography>
                <Typography variant="body1">
                    We will only retain your personal data for as long as necessary to fulfil the
                    purposes we collected it for, including for the purposes of satisfying any legal,
                    accounting, or reporting requirements.
                    <br />
                    <br />
                    To determine the appropriate retention period for personal data, we consider
                    the amount, nature, and sensitivity of the personal data, the potential risk of
                    harm from unauthorised use or disclosure of your personal data, the purposes
                    for which we process your personal data and whether we can achieve those
                    purposes through other means, and the applicable legal requirements.
                    <br />
                    <br />
                    Upon expiry of the applicable retention period, we will securely destroy your
                    personal data in accordance with applicable laws and regulations. In some
                    circumstances, we may anonymise your personal data so that it can no longer
                    be associated with you, in which case it is no longer personal data.
                </Typography>

                <Typography variant="subtitle1" mt={3.75} mb={1.875} fontWeight={700}>
                    Publicly Accessible Information
                </Typography>
                <Typography variant="body1">
                    We offer you the opportunity to post, submit and exchange content and information
                    on publicly accessible areas of our Website. You should exercise caution if you
                    choose to disclose personally identifiable information in a publicly accessible
                    area. Remember that this information may remain indefinitely in the publicly
                    accessible area of the website. Also, other users may also be able to view two or
                    more of your individual postings collectively. You are solely responsible for any
                    information that you disclose and disseminate on publicly accessible areas. If
                    you have a concern about any personally identifiable information you have posted
                    on any publicly accessible areas and are unable to delete or edit it, please contact us.
                </Typography>

                <Typography variant="subtitle1" mt={3.75} mb={1.875} fontWeight={700}>
                    Anonymous Data Collected Through This Website
                </Typography>
                <Typography variant="body1">
                    In addition to the information we collect as described above, we use
                    technology to collect anonymous information about the use of our
                    website. For example, we use technology to track which pages of our
                    website our visitors view. We also use technology to determine which Web
                    browsers our visitors use. This technology does not identify you
                    personally; it simply enables us to compile statistics about our
                    visitors and their use of our website.
                    <br />
                    <br />
                    We use this anonymous data to improve the content and functionality of
                    our website and our e-mail updates, to better understand our customers
                    and visitors, and to improve the services we offer.
                </Typography>

                <Typography variant="subtitle1" mt={3.75} mb={1.875} fontWeight={700}>
                    Cookies
                </Typography>
                <Typography variant="body1">
                    To collect the anonymous data described in the preceding paragraph, we
                    may use temporary "cookies" that remain in the cookies file of your
                    browser until the browser is closed. Cookies by themselves cannot be
                    used to discover the identity of the user. A cookie is a small piece of
                    information that is sent to your browser and stored on your computer's
                    hard drive. Cookies do not damage your computer. You can set your
                    browser to notify you when you receive a cookie. This enables you to
                    decide if you want to accept it or not. We also use your IP address to
                    help diagnose problems with our server and to administer our website. An
                    IP address is a numeric code that identifies your computer on a network,
                    or in this case, the Internet. Your IP address is also used to gather
                    broad demographic information, such as determining how many of our
                    visitors are from outside of the UK. We may also perform IP lookups to
                    determine which domain you are coming from (i.e., aol.com,
                    yourcompany.com) to assess our users' demographics more accurately.
                </Typography>

                <Typography variant="subtitle1" mt={3.75} mb={1.875} fontWeight={700}>
                    Disclosure of Your Personal Data
                </Typography>
                <Typography variant="body1">
                    We do not share with, nor sell, nor distribute your personal data to
                    unrelated third parties, except under these limited circumstances:
                </Typography>
                <br />
                <br />
                <ul>
                    <li>
                        <Typography variant="body1">
                            Personal data may occasionally be transferred to third parties who
                            act for us for further processing in accordance with the purposes
                            for which the data was originally collected or for purposes to
                            which you have consented or otherwise in accordance with this
                            Privacy Policy. For example, sometimes a third party may have access
                            to your personal data in order to support our information technology
                            or to handle mailings on our behalf.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            We will provide your information to third-party companies to perform
                            services on our behalf, including payment processing, data analysis,
                            e-mail delivery, hosting services, and customer service.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            We direct all such third-party service providers to maintain the
                            confidentiality of the information disclosed to them and not to use
                            your information for any purpose other than to provide services on
                            our behalf. Please note that payments made by third-party payment
                            providers may also be subject to additional policies.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            Unless you have given us your consent to the processing of your
                            personal data in accordance with our Privacy Policy (which includes
                            sharing or other disclosing), we will not share or disclose your
                            personal data to any unrelated third parties for their own marketing
                            purposes.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            We may share or transfer the information in our databases to comply
                            with a legal or regulatory requirement, for the administration of
                            justice, interacting with anti-fraud databases, to protect your
                            vital interests, to protect the security or integrity of our
                            databases or this website, to take precautions against legal
                            liability, or in the event of our sale, merger, reorganization,
                            transfer of business, dissolution, or similar event.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            If we sell or buy any business or assets, in which case we may
                            disclose your personal data to the prospective seller or buyer of
                            such business or assets, but only once such transaction has gone
                            through.
                        </Typography>
                    </li>
                </ul>
                <Typography variant="body1">
                    Where appropriate, before disclosing personal data to a third party, we
                    require the third party, contractually, to take adequate precautions to
                    protect that data and to comply with applicable law.
                </Typography>

                <Typography variant="subtitle1" mt={3.75} mb={1.875} fontWeight={700}>
                    Limitation of Liability
                </Typography>
                <Typography variant="body1">
                    Where appropriate, before disclosing personal data to a third party, we
                    require the third party, contractually, to take adequate precautions to
                    protect that data and to comply with applicable law.
                </Typography>
                <Typography variant="body1">
                    WooGlobe’s total liability for any claims arising from or related to the use of this Website, its services, or any agreement between you and WooGlobe shall, to the extent permitted by law, be limited to the amount you have actually paid to WooGlobe (if any) in the 12 months preceding the claim.
                </Typography>
                <Typography variant="body1">
                    While WooGlobe implements appropriate technical and organizational security measures to protect your personal data, no system is completely secure. In the event of a data breach, WooGlobe will take reasonable steps to mitigate any damage and comply with all applicable legal notification requirements. However, to the fullest extent permitted by law, WooGlobe shall not be liable for:
                </Typography>
                <br />
                <br />
                <ul>
                    <li>  <Typography variant="body1">Any unauthorized access to, alteration, disclosure, or loss of personal data caused by factors beyond our reasonable control, including cyber-attacks, system failures, or third-party misconduct.</Typography></li>
                    <li> <Typography variant="body1">Any loss or damage suffered due to reliance on information obtained from or through our Website.</Typography></li>
                    <li> <Typography variant="body1">Any indirect, incidental, or consequential damages arising from a security breach unless such breach was caused by WooGlobe’s gross negligence or willful misconduct.</Typography></li>
                </ul>
                <Typography variant="body1">
                    We strive to maintain the reliability, accuracy, completeness, and
                    currency of personal data in our databases and to protect the privacy
                    and security of our databases. The security measures in place on our
                    website and computer systems aim to protect the loss, misuse, or
                    alteration of the information you provide to us. We keep your personal
                    data only for as long as reasonably necessary for the purposes for which
                    it was collected or to comply with any applicable legal or regulatory
                    requirements or our data retention policy.
                    <br />
                    <br />
                    We urge you to protect your own privacy. We recommend that you do not
                    share your password with anyone or share your password in an unsolicited
                    phone call or e-mail.
                    <br />
                    <br />
                    Unfortunately, despite all of our efforts, there is always a risk that
                    third parties may unlawfully intercept transmissions. For example, we
                    cannot ensure the security of your data during transmission to us or the
                    security of your data on an iPhone, Android (or other telephone device
                    that can use phone applications) or on another computer. This reality is
                    true of all Internet or data transmission use. As a result, we cannot
                    ensure the security of any information you transmit, and you transmit
                    all information at your own risk. We have put in place procedures to
                    deal with any suspected personal data breach and will notify you and any
                    applicable regulator of a breach where we are legally required to do so.
                </Typography>

                <Typography variant="subtitle1" mt={3.75} mb={1.875} fontWeight={700}>
                    Location-Based Services
                </Typography>
                <Typography variant="body1">
                    When you upload videos, data, and/or images ("Content") using the
                    WooGlobe App on a device, we collect location information. We collect
                    two forms of location data, in both instances pertaining to the Content
                    you submit: (i) GPS Geographic Location Data from the App installed on
                    your device (ii) Image File Data: we collect EXIF file metadata, which
                    is usually automatically tagged to your Content. We collect and use both
                    of these two sources of location information to verify the geo-location
                    of the Content you submit, and we also pass this information to our
                    third-party media buyers for the same purpose. We obtain your consent to
                    collect this data on the registration page of our website, and on the
                    first instance you use the WooGlobe App on your device.
                </Typography>

                <Typography variant="subtitle1" mt={3.75} mb={1.875} fontWeight={700}>
                    Inaccurate Data
                </Typography>
                <Typography variant="body1">
                    We encourage you to ensure that your personal data is accurate and kept up
                    to date, so please regularly update any information you have given to us by
                    sending an email to {" "}
                    <a href="mailto:privacy@WooGlobe.com">privacy@WooGlobe.com</a>,
                    or by writing to us.
                </Typography>

                <Typography variant="subtitle1" mt={3.75} mb={1.875} fontWeight={700}>
                    Links to other websites
                </Typography>
                <Typography variant="body1">
                    Our Website may contain hyperlinks to websites that are not operated by us.
                    Any such hyperlinks are provided for your reference and convenience only
                    and do not imply any endorsement of the activities of these third-party
                    websites or any association with their operators. We do not control these
                    websites and are not responsible for their data or privacy practices. We
                    urge you to review any privacy policy posted on any site you visit before
                    using the site or providing any personal data about yourself.
                    <br />
                    <br />
                    Our Website contains hyperlinks to other pages on our Website. We may use
                    technology to track how often these links are used and which pages on our
                    Website our visitors choose to view. Again, this technology does not
                    identify you personally - it simply enables us to compile statistics
                    about the use of these hyperlinks.
                </Typography>

                <Typography variant="subtitle1" mt={3.75} mb={1.875} fontWeight={700}>
                    Transfer of Data Abroad
                </Typography>
                <Typography variant="body1">
                    We may transfer data between WooGlobe's offices and third-party processors,
                    which may be located outside the EEA. Where your personal data is transferred
                    outside the EEA we will ensure that the recipient agrees to keep your
                    information confidential and hold it securely in accordance with the
                    requirements of applicable data legislation. We ensure that all cross-border
                    data transfers are compliant with GDPR using Standard Contractual Clauses
                    or other approved mechanisms.
                    <br />
                    <br />
                    If you are visiting our website from a country other than the country in which
                    our servers are located, the various communications will necessarily result in
                    the transfer of information across international boundaries.
                    <br />
                    <br />
                    By providing us with your personal data, you consent to the processing and
                    transfer of your data as set out in this section.
                </Typography>

                <Typography variant="subtitle1" mt={3.75} mb={1.875} fontWeight={700}>
                    Your Data Rights
                </Typography>
                <Typography variant="body1">
                    We want you to remain in control of your personal information. Part of this
                    is making sure you understand your legal rights, which are as follows:
                </Typography>
                <br />
                <br />
                <ol style={{ fontWeight: 600 }}>
                    <li>
                        <Typography variant="body1">
                            where your personal information is processed on the basis of consent,
                            the right to withdraw that consent.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            the right to confirmation as to whether or not we are holding any of
                            your personal information and, if we are, to obtain a copy of it.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            the right to have certain data provided to you in a portable electronic
                            format (where technically feasible).
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            the right to have inaccurate personal information rectified.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            the right to object to your personal information being used for marketing
                            or profiling, or on the basis of our or a third party's legitimate interests.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            the right to restrict how your personal information is used; and
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            the right to be forgotten, which allows you to have your personal information
                            erased in certain circumstances (though this is not an absolute right and
                            may not apply if we need to continue using it for a lawful reason).
                        </Typography>
                    </li>
                </ol>

                <Typography variant="body1">
                    Please keep in mind that there are exceptions to the rights above and, though we
                    will always try to respond to your satisfaction, there may be situations where
                    we are unable to do so (for example, because the information no longer exists
                    or there is an exception which applies to your request).
                    <br />
                    <br />
                    If you are not happy with our response, or you believe that your data protection
                    or privacy rights have been infringed, you should contact the UK Information
                    Commissioner's Office, which oversees data protection compliance in the UK.
                    Details of how to do this can be found at {" "}
                    <a href="https://www.ico.org.uk">www.ico.org.uk.</a>
                </Typography>

                <Typography variant="subtitle1" mt={3.75} mb={1.875} fontWeight={700}>
                    Unsubscribe
                </Typography>
                <Typography variant="body1">
                    We hope you will be delighted with our services. If you no longer wish to
                    receive information from us and want to be removed from our standard
                    mailing list you can "unsubscribe" by email or post by contacting us
                    at the address given below. We will remove you from mailing lists as
                    soon as practicable but please allow up to thirty days for the unsubscribe
                    process to be completed.
                    <br />
                    <br />
                    Remember that by stopping the information that you receive from us about
                    our products and services may not benefit you.
                    <br />
                    <br />
                    If you do not want to receive unsolicited mail from any source, you can
                    stop this by registering with the "Mailing Preference Service". There
                    are also telephone, fax, and email preference services. For more
                    information on the preference services, please visit the Direct
                    Marketing Association's website.
                </Typography>

                <Typography variant="subtitle1" mt={3.75} mb={1.875} fontWeight={700}>
                    Governing Law
                </Typography>
                <Typography variant="body1">
                    This Privacy Policy forms part of our website Terms of Business and as
                    such shall be governed by and construed in accordance with the laws of
                    England and Wales. You agree to submit any dispute arising out of your
                    use of this website to the exclusive jurisdiction of the courts of England.
                </Typography>

                <Typography variant="subtitle1" mt={3.75} mb={1.875} fontWeight={700}>
                    Contact Details and Data Access
                </Typography>
                <Typography variant="body1">
                    We have appointed a data privacy manager who is responsible for overseeing
                    questions in relation to this privacy notice. If you have any questions
                    about this privacy notice, including any requests to exercise your rights,
                    please contact the data privacy manager using the details set out below:
                    Our postal address is:
                    <br />
                    <br />
                    WooGlobe Limited <br />
                    9 Barn End Drive <br />
                    Wilmington, DA2 7BX <br />
                    United Kingdom
                    <br />
                    <br />
                    You have the right to make a complaint at any time to the Information
                    Commissioner's Office (ICO), the UK supervisory authority for data
                    protection issues (www.ico.org.uk). We would, however, appreciate the
                    chance to deal with your concerns before you approach the ICO so please
                    contact us in the first instance.
                </Typography>

                <Typography variant="subtitle1" mt={3.75} mb={1.875} fontWeight={700}>
                    No fee usually required
                </Typography>
                <Typography variant="body1">
                    You will not have to pay a fee to access your personal data (or to
                    exercise any of the other rights). However, we may charge a reasonable
                    fee if your request is clearly unfounded, repetitive, or excessive.
                    Alternatively, we may refuse to comply with your request in these
                    circumstances.
                </Typography>

                <Typography variant="subtitle1" mt={3.75} mb={1.875} fontWeight={700}>
                    What we may need from you
                </Typography>
                <Typography variant="body1">
                    We may need to request specific information from you to help us confirm
                    your identity and ensure your right to access your personal data (or to
                    exercise any of your other rights). This is a security measure to ensure
                    that personal data is not disclosed to any person who has no right to
                    receive it. We may also contact you to ask you for further information
                    in relation to your request to speed up our response.
                </Typography>

                <Typography variant="subtitle1" mt={3.75} mb={1.875} fontWeight={700}>
                    Time limit to respond
                </Typography>
                <Typography variant="body1">
                    We try to respond to all legitimate requests within one month. Occasionally
                    it may take us longer than a month if your request is particularly complex
                    or you have made a number of requests. In this case, we will notify you and
                    keep you updated.
                </Typography>
            </Box>
        </Container>
    );
}
