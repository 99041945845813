import * as React from "react";
import { styled /*, alpha */ } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
// import InputBase from "@mui/material/InputBase";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItems from "./MenuItems.js";
import AccountCircle from "@mui/icons-material/AccountCircle";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
// import SearchIcon from "@mui/icons-material/Search";
import homeIcon from "../../static/icons/logo.svg";
import LogoutIcon from "@mui/icons-material/Logout";
import { connect } from "react-redux";
import { authLogout } from "../../store/actions/actions";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import api from "../../conf/api.js";
import { Scrollbars } from "react-custom-scrollbars-2";
import CircleIcon from "@mui/icons-material/Circle";
import { useEffect } from "react";
import "./shakeBell.css";
import { sleep } from "../../utils.js";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const drawerWidth = 270;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer,
  backgroundColor: "#fff",
  height: 65,
  [theme.breakpoints.up("md")]: {
    width: `calc(100% - 65px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  },
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  zIndex: theme.zIndex.drawer,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// const Search = styled("div")(({ theme }) => ({
//   position: "relative",
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.black, 0.2),
//   "&:hover": {
//     backgroundColor: alpha(theme.palette.common.black, 0.25),
//   },
//   marginLeft: 0,
//   width: "100%",
//   [theme.breakpoints.up("sm")]: {
//     marginLeft: theme.spacing(1),
//     width: "auto",
//   },
// }));

// const SearchIconWrapper = styled("div")(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: "100%",
//   position: "absolute",
//   pointerEvents: "none",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: "inherit",
//   "& .MuiInputBase-input": {
//     color: theme.palette.common.white,
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create("width"),
//     width: "100%",
//     [theme.breakpoints.up("sm")]: {
//       width: "12ch",
//       "&:focus": {
//         width: "20ch",
//       },
//     },
//   },
// }));

function SideDrawer(props) {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(!isSmallScreen);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notificationAnchor, setNotificationAnchorEl] = React.useState(null);
  const [unreadNotifications, setUnreadNotifications] = React.useState(0);
  const [notifications, setNotifications] = React.useState([]);

  const fetchNotifications = async () => {
    try {
      await api.get("/notification").then((res) => {
        const data = res.data;
        setNotifications(data);
        setUnreadNotifications(
          data.filter((notification) => !notification.is_read).length
        );
      });
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const handleNotificationClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
    setUnreadNotifications(0);
    markNotificationsAsRead();
  };
  const markNotificationsAsRead = async () => {
    try {
      await api.post("/notification/", {
        notifications: notifications.map((n) => n.id),
      });
      fetchNotifications();
    } catch (error) {
      console.error("Error marking notifications as read:", error);
    }
  };

  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const MenuBarBody = () => {
    return !isSmallScreen ? (
      <Drawer variant="permanent" open={open}>
        <DrawerHeader sx={{ ml: 1.1 }}>
          <Box display="flex" gap={2} alignItems="center" width="100%" >
            <Box component="img" src={homeIcon} height={"35px"} alt="logo" />
            <Typography variant="body1">
              CreatorVault
            </Typography>
          </Box>
          {open &&
            <IconButton
              aria-label="close drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                color: "white",
              }}
            >
              <KeyboardArrowLeftIcon sx={{ color: "grey.500" }} />
            </IconButton>
          }
        </DrawerHeader>
        <Divider />
        <MenuItems open={open} setOpen={setOpen} />
      </Drawer>
    ) : (
      <MuiDrawer
        open={open}
        onClose={handleDrawerOpen}
        PaperProps={{ sx: { width: 238 } }}
      >
        <DrawerHeader >
          <Box display="flex" gap={2} alignItems="center" width="100%">
            <Box component="img" src={homeIcon} height={"35px"} alt="logo" />
            <Typography variant="body1">
              CreatorVault
            </Typography>
          </Box>
        </DrawerHeader>
        <Divider />
        <MenuItems open={open} setOpen={setOpen} />
      </MuiDrawer>
    );
  };

  useEffect(() => {
    fetchNotifications();
    // Function to create a WebSocket connection
    const createWebSocket = () => {
      const socket = new WebSocket(
        `${process.env.REACT_APP_SOCKET_BASE_URL}/ws/notify/`
      );

      socket.onopen = function (e) {
        console.log("Socket successfully connected.");
      };

      socket.onclose = function (e) {
        console.log("Socket closed unexpectedly");
        // Try to reconnect every 5 seconds
        // setTimeout(createWebSocket, 5000);
      };

      socket.onmessage = function (e) {
        const data = JSON.parse(e.data);

        document.getElementById("bellIcon").className = "shakeIt";
        const wait = async () => {
          await sleep(2000);
          document.getElementById("bellIcon").className = "";
        };
        wait();
        setNotifications((prevNotifications) => [data, ...prevNotifications]);
        setUnreadNotifications((prevCount) => prevCount + 1);
      };

      return socket;
    };
    let socket = null
    if (!props.user?.creator) {
      const socket = createWebSocket();
    }

    return () => {
      if (!props.user?.creator) {
        socket?.close();
      }
    };
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <Box display="flex" flexGrow={1}>
            {!open &&
              <IconButton
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 2,
                  color: "gray.50",
                }}
              >
                <MenuIcon />
              </IconButton>
            }
            {/* <Box component={Search}>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
              />
            </Box> */}
          </Box>
          {/* Notification  */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>

            <Button color="success" size="small" sx={{ mt: 0.5 }} startIcon={<DocumentScannerIcon />} onClick={() => {
              window.open('https://docs.google.com/spreadsheets/d/1qs3-DP28Y9xCPh6qL_KG3sJvxiN9KN510P3LsPhiglk/edit?usp=sharing', '_blank')
            }}>
              Whitelisting
            </Button>

            <Button color="success" size="small" sx={{ mt: 0.5 }} startIcon={<DocumentScannerIcon />} onClick={() => {
              window.open('https://docs.google.com/spreadsheets/d/1uJi4YJ7jHX9MoifCHXSv7NucnlE-ZumLlzaUy0Cr3DI/edit?usp=sharing', '_blank')
            }}>
              Assets Sheet
            </Button>
            {!props.user?.creator &&
              <IconButton
                size="large"
                onClick={handleNotificationClick}
                color="primary"
              >
                <div id="bellIcon">
                  <Badge badgeContent={unreadNotifications} color="error">
                    <NotificationsIcon />
                  </Badge>
                </div>
              </IconButton>
            }
            <Menu
              id="menu-notifications"
              anchorEl={notificationAnchor}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(notificationAnchor) && notifications.length > 0}
              onClose={handleNotificationClose}
            >
              <Scrollbars style={{ width: 300, height: 500 }}>
                {notifications.map((notification, index) => (
                  <MenuItem key={index} onClick={handleNotificationClose}>
                    <Box display="flex" maxWidth={300} flexDirection="column" p={1} width={"100%"}>
                      <Typography
                        sx={{ textWrap: "pretty", fontSize: 12 }}
                        align="justify"
                        fontWeight={600}
                        variant="body1"
                      >
                        {!notification.is_read && (
                          <span>
                            <CircleIcon fontSize="small" color="success" />{" "}
                          </span>
                        )}
                        {notification.message}
                      </Typography>

                      <Typography variant="caption">
                        {new Date(notification.date).toLocaleString()}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Scrollbars>
            </Menu>
          </Box>
          {/* Account Menu */}
          <div>
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
            >
              <AccountCircle fontSize="large" />
            </IconButton>
            <Typography
              variant="subtitle5"
              color="#3f51b5"
              fontWeight={600}
            >
              {props.user?.username}
            </Typography>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <AccountCircle fontSize="small" />
                </ListItemIcon>
                <ListItemText>{props.user?.full_name}</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  props.authLogOut();
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Logout</ListItemText>
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <MenuBarBody />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {props.children}
      </Box>
    </Box>
  );
}

const mapStatesToProps = (state) => {
  return {
    user: state.user
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    authLogOut: () => dispatch(authLogout()),
  };
};
export default connect(mapStatesToProps, mapDispatchToProps)(SideDrawer);
