import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Collapse,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import GrainIcon from "@mui/icons-material/Grain";
import { Link, useSearchParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import api from "../../conf/api";
import Scrollbars from "react-custom-scrollbars-2";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import UserPopupBox from "./components/UserPopupBox.js";
import { connect } from "react-redux";
import { authSignup } from "../../store/actions/actions.js";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyIcon from '@mui/icons-material/Key';
import ConfirmDeleteUserBox from "./components/ConfirmDeleteUserBox.js";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import PasswordResetBox from "./components/PasswordResetBox.js";
import UserChannalsPreview from "./components/UserChannelsPreview.js";
import TvIcon from '@mui/icons-material/Tv';
import { toast } from "react-toastify";

function StaffList(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const screen = searchParams.get("screen");
  const [views, setView] = useState();
  const [params, setParams] = useState(null);
  const [data, setData] = useState([]);
  const [userBox, setUserBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowCountState, setRowCountState] = useState(0);
  const [is_update, setIsUpdate] = useState(false);
  const [selectedRow, setSelectedRow] = useState(0);
  const [confirmBox, setConfirmBox] = useState(false);
  const [passReset, setPassReset] = useState(false);
  const [channalPreviewBox, showChannalPreview] = useState(false);

  const handleupdateUser = (id) => () => {
    setSelectedRow(id);
    setIsUpdate(true);
    setUserBox(true);
  };
  const handleDeletetUser = (id) => () => {
    setSelectedRow(id);
    setConfirmBox(true);
  };
  const handlePassReset = (user) => () => {
    setSelectedRow(user);
    setPassReset(true);
  };
  const handleChannelPreview = (user) => () => {
    setSelectedRow(user);
    showChannalPreview(true);
  }

  async function fetchData() {
    await api
      .get("/users/list", {
        params: params,
      })
      .then((res) => {
        setData(res.data.results);
        setRowCountState(res.data.count);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(
    () => {
      let _params = { is_creator: false, is_admin: false, is_promouser: false };
      switch (views) {
        case "creator":
          _params.is_creator = true;
          break;
        case "promo":
          _params.is_promouser = true;
          break;
        default:
          _params.is_admin = true;
          break;
      }
      setParams(_params);
    },
    // eslint-disable-next-line
    [views]
  );
  useEffect(
    () => {
      if (params !== null) {
        fetchData();
      }
    },
    // eslint-disable-next-line
    [params]
  );
  useEffect(() => {
    if (!views) {
      setView(screen);
    }
    if (screen === null) {
      setView("admin");
    }
    // eslint-disable-next-line
  }, [screen]);
  useEffect(() => {
    if (views !== screen) {
      const queryParams = new URLSearchParams(searchParams);
      queryParams.set("screen", views);
      setSearchParams(queryParams);
    }
    // eslint-disable-next-line
  }, [views]);

  const styles = {
    "& .deleted-row": {
      backgroundColor: "rgba(255, 0, 0, 0.1)",
    },
  };

  const columns = [
    { field: "id", headerName: "ID", sortable: true, width: 90 },
    { field: "username", headerName: "User", sortable: true, width: 200 },
    { field: "email", headerName: "Email", sortable: true, minWidth: 200 },
    {
      field: "paypal_email",
      headerName: "Paypal Email",
      sortable: true,
      minWidth: 200,
    },
    {
      field: "country",
      headerName: "Country",
      sortable: true,
    },
    {
      field: "promo_shares",
      headerName: "Revenue share",
      sortable: true,
      minWidth: 120,
    },
    {
      field: "address",
      headerName: "Address",
      sortable: true,
      minWidth: 200,
      flex: 1,
    },
    {
      field: "is_active",
      headerName: "Active",
      sortable: true,
      align: "center",
      width: 90,
      type: "boolean",
    },
    {
      field: "deleted",
      headerName: "Deleted",
      sortable: true,
      width: 90,
      align: "center",
      type: "boolean",
    },
    {
      field: "date_joined",
      headerName: "Created Date",
      sortable: true,
      width: 180,
      type: "dateTime",
      valueGetter: (params) => new Date(params.row.date_joined),
    },
    {
      field: "action",
      headerName: "Action",
      align: "center",
      headerAlign: "center",
      sortable: true,
      width: 220,
      renderCell: (params) => (
        <Box display="flex" gap={1}>
          {params.row.is_promouser &&
            <Tooltip title="Channals view">
              <IconButton size="small" onClick={handleChannelPreview(params.row)}>
                <TvIcon fontSize="small" color="primary" />
              </IconButton>
            </Tooltip>
          }
          {params.row.contract && (
            <Tooltip title="Download Contract">
              <Box component="a" target="_blank" display="flex" alignItems="center" href={params.row.contract.replace("http:", "https:")}>
                <DescriptionOutlinedIcon fontSize="small" color="primary" />
              </Box>
            </Tooltip>
          )}
          <Tooltip title="Edit User">
            <IconButton size="small" onClick={handleupdateUser(params.row.id)}>
              <CreateIcon fontSize="small" color="primary" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete User">
            <IconButton size="small" onClick={handleDeletetUser(params.row.id)}>
              <DeleteIcon fontSize="small" sx={{ color: "error.dark" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Reset Password">
            <IconButton size="small" onClick={handlePassReset(params.row)}>
              <KeyIcon fontSize="small" sx={{ color: "coral" }} />
            </IconButton>
          </Tooltip>
          {!params.row.is_verified &&
            <Tooltip title="Genrate New verification Key">
              <IconButton size="small" onClick={RegenrateVerificationLink(params.row)}>
                <KeyIcon fontSize="small" sx={{ color: "blue" }} />
              </IconButton>
            </Tooltip>
          }
        </Box>
      ),
    },
  ];

  const UserDialog = useCallback(
    () => (
      <UserPopupBox
        selectedRow={selectedRow}
        is_update={is_update}
        open={userBox}
        setOpen={setUserBox}
        registerUser={props.authSignup}
        refreshFunc={fetchData}
        is_promouser={views === "promo"}
        is_staff={views === "admin"}
        is_creator={views === "creator"}
      />
    ),
    // eslint-disable-next-line
    [selectedRow, is_update, userBox]
  );

  const RegenrateVerificationLink = (user) => async () => {
    api.post("/auth/verification/regen/", { email: user.email }, { timeout: 1000 * 60 * 5 }).then(res => {
      if (res.status === 200) {
        toast.info(res.data);
        fetchData();
      }
    }).catch(err => {
      toast.error("Something went wrong.")
    })

  }

  const DeleteUserDialog = useCallback(
    () => (
      <ConfirmDeleteUserBox
        selectedRow={selectedRow}
        open={confirmBox}
        setOpen={setConfirmBox}
        refreshFunc={fetchData}
      />
    ),
    // eslint-disable-next-line
    [confirmBox, selectedRow]
  );

  const PassResetDialog = useCallback(
    () => (
      <PasswordResetBox
        selectedRow={selectedRow}
        open={passReset}
        setOpen={setPassReset}
      />
    ),
    // eslint-disable-next-line
    [passReset, selectedRow]
  );

  const UserChannelsPreviewBox = useCallback(
    () => (
      <UserChannalsPreview
        selectedRow={selectedRow}
        open={channalPreviewBox}
        setOpen={showChannalPreview}
      />
    ),
    [channalPreviewBox, selectedRow]
  );

  return (
    <Box>
      <Box
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h4" sx={{ color: "grey.600" }} fontWeight={"bold"}>
          Users List
        </Typography>
        <Box
          sx={{ bgcolor: "grey.300" }}
          p={2}
          borderRadius={6.5}
          role="presentation"
          onClick={() => { }}
        >
          <Breadcrumbs aria-label="breadcrumb">
            <Box
              component={Link}
              color="grey"
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              to="/"
            >
              <HomeIcon sx={{ mr: 0.5 }} />
              <Typography variant="subtitle2">Admin Protal</Typography>
            </Box>
            <Box
              component={Link}
              color="grey"
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              to="/"
            >
              <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <Typography variant="subtitle2">Staff Managent</Typography>
            </Box>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="primary.light"
            >
              <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <Typography variant="subtitle2">List Staff</Typography>
            </Typography>
          </Breadcrumbs>
        </Box>
      </Box>
      <Box display="flex" gap={2.5} mt={5}>
        <Button
          onClick={() => {
            setView("admin");
          }}
          sx={{
            borderRadius: 6,
            fontWeight: "bold",
            fontSize: views === "admin" ? 16 : 18,
            textTransform: "none",
            color: views === "admin" ? "white" : "grey",
            boxShadow:
              views === "admin" &&
              "0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgb(102, 115, 252)",
          }}
          size="large"
          variant={views === "admin" ? "contained" : "text"}
        >
          Users View
        </Button>
        <Button
          onClick={() => {
            setView("creator");
          }}
          disableFocusRipple={views === "creator"}
          sx={{
            borderRadius: 6,
            fontWeight: "bold",
            fontSize: views === "creator" ? 16 : 18,
            textTransform: "none",
            color: views === "creator" ? "white" : "grey",

            boxShadow:
              views === "creator" &&
              "0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgb(102, 115, 252)",
          }}
          size="large"
          variant={views === "creator" ? "contained" : "text"}
        >
          Creator View
        </Button>
        <Button
          onClick={() => {
            setView("promo");
          }}
          disableFocusRipple={views === "promo"}
          sx={{
            borderRadius: 6,
            fontWeight: "bold",
            fontSize: views === "promo" ? 16 : 18,
            textTransform: "none",
            color: views === "promo" ? "white" : "grey",

            boxShadow:
              views === "promo" &&
              "0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgb(102, 115, 252)",
          }}
          size="large"
          variant={views === "promo" ? "contained" : "text"}
        >
          Promo View
        </Button>
      </Box>
      {/* For Users View */}
      <Collapse in={views === "admin" || views === null} unmountOnExit>
        <Box mt={4} component={Paper} borderRadius={4}>
          <Box
            border="1px solid"
            borderColor="grey.400"
            borderRadius={"10px 10px 0px 0px"}
            p={1}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography color="grey" fontWeight={600}>
              All {views}
            </Typography>
            <Tooltip title="Add new users">
              <IconButton
                size="small"
                onClick={() => {
                  setIsUpdate(false);
                  setUserBox(true);
                }}
              >
                <AddCircleIcon color="success" />
              </IconButton>
            </Tooltip>
          </Box>
          <Scrollbars style={{ height: 500, width: "100%" }}>
            <DataGrid
              rows={data}
              columns={columns.filter((c) => c.field !== "channels")}
              loading={loading}
              sx={styles}
              pageSize={10}
              rowCount={rowCountState}
              rowSelection={false}
              disableColumnSelector
              disableDensitySelector
              disableSelectionOnClick
              getRowClassName={(params) => {
                if (params.row.deleted) {
                  return "deleted-row";
                }
                return "";
              }}
            />
          </Scrollbars>
        </Box>
      </Collapse>
      {/* For Creator view */}
      <Collapse in={views === "creator"} unmountOnExit>
        <Box mt={4} component={Paper} borderRadius={4}>
          <Box
            border="1px solid"
            borderColor="grey.400"
            borderRadius={"10px 10px 0px 0px"}
            p={1}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography color="grey" fontWeight={600}>
              All {views}
            </Typography>
            <Tooltip title="Add new users">
              <IconButton
                size="small"
                onClick={() => {
                  setIsUpdate(false);
                  setUserBox(true);
                }}
              >
                <AddCircleIcon color="success" />
              </IconButton>
            </Tooltip>
          </Box>
          <Scrollbars style={{ height: 500, width: "100%" }}>
            <DataGrid
              rows={data}
              columns={columns.filter((c) => c.field !== "channels")}
              loading={loading}
              sx={styles}
              pageSize={10}
              rowCount={rowCountState}
              rowSelection={false}
              disableColumnSelector
              disableDensitySelector
              disableSelectionOnClick
              getRowClassName={(params) => {
                if (params.row.deleted) {
                  return "deleted-row";
                }
                return "";
              }}
            />
          </Scrollbars>
        </Box>
      </Collapse>
      {/* For Promo view */}
      <Collapse in={views === "promo"} unmountOnExit>
        <Box mt={4} component={Paper} borderRadius={4}>
          <Box
            border="1px solid"
            borderColor="grey.400"
            borderRadius={"10px 10px 0px 0px"}
            p={1}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography color="grey" fontWeight={600}>
              All {views}
            </Typography>
            <Tooltip title="Add new users">
              <IconButton
                size="small"
                onClick={() => {
                  setIsUpdate(false);
                  setUserBox(true);
                }}
              >
                <AddCircleIcon color="success" />
              </IconButton>
            </Tooltip>
          </Box>
          <Scrollbars style={{ height: 500, width: "100%" }}>
            <DataGrid
              rows={data}
              columns={columns}
              loading={loading}
              sx={styles}
              pageSize={10}
              rowCount={rowCountState}
              rowSelection={false}
              disableColumnSelector
              disableDensitySelector
              disableSelectionOnClick
              getRowClassName={(params) => {
                if (params.row.deleted) {
                  return "deleted-row";
                }
                return "";
              }}
            />
          </Scrollbars>
        </Box>
      </Collapse>
      {/* Add new user */}
      <UserDialog />
      {/* Confirm Box  */}
      <DeleteUserDialog />
      {/* Password Reset Box  */}
      <PassResetDialog />
      {/* Channals Preview Box */}
      <UserChannelsPreviewBox />
    </Box>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    authSignup: (fields, afterSuccess) =>
      dispatch(authSignup(fields, afterSuccess)),
  };
};
export default connect(null, mapDispatchToProps)(StaffList);
