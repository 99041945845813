import React from "react";
import { Scrollbars } from "react-custom-scrollbars-2"
import TermsofServices from "./TermsofServices";
import AppearanceRelease from "./ApperanceRelease";
import PrivacyPolicy from "./PrivacyPolicy";
import { Box } from "@mui/material";


function TermsDialog({ page = 1 }) {
  return (
    <Box>
      {page === 1 &&
        <TermsofServices />
      }
      {page === 2 &&
        <AppearanceRelease />
      }
      {page === 3 &&
        <PrivacyPolicy />
      }
    </Box>
  );
}

export default TermsDialog;

