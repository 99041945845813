import React, { useState, useEffect, useCallback } from "react";
import { DataGrid } from "@mui/x-data-grid";
import api from "../../conf/api";
import { Autocomplete, Box, Button, CircularProgress, Divider, IconButton, Link, MenuItem, Paper, Select, TextField, Tooltip, Typography } from "@mui/material";
import UserAutoComplete from "../../Components/AutoCompletes/UserAutoComplete";
import Scrollbars from "react-custom-scrollbars-2";
import SyncIcon from '@mui/icons-material/Sync';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import YouTubeIcon from '@mui/icons-material/YouTube';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmDeleteYoutubeVideoDialog from "./components/DialogBoxes/ConfirmDeleteYoutubeVideoDialog";
import YoutubeUpdateVideoStatusDialog from "./components/DialogBoxes/YoutubeUpdateVideoStatusDialog ";
import { THREAD_STATUS_ENUM } from "./Enums/enums";
import YoutubePublishingRetryDialog from "./components/DialogBoxes/YoutubePublishingRetryDialog";
import { useSearchParams } from "react-router-dom";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

function VideosPreview() {
  const initialStates = {
    user: null,
    channel: null,
  }
  const initialPagination = {
    page: 0,
    rowPerPage: 10,
    count: 0,
  };
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const screen = searchParams.get("screen");
  const orderBy = searchParams.get("orderBy") || "-created_date";
  const channel = searchParams.get("channel");
  const [deleteYtDialog, showDeleteYtDialog] = useState(false);
  const [updateYtDialog, showUpdateYtDialog] = useState(false);
  const [retryConfirmationDialog, showRetryConfirmationDialog] = useState(false);
  const [sortField, setSortField] = useState(orderBy);
  const [selectedRow, selectRow] = useState({});
  const [filters, setFilters] = useState(initialStates);
  const [pagination, setPagination] = useState(initialPagination);
  const [views, setView] = useState(screen ? screen : "completed");
  const [parameters, setParams] = useState(null);
  const [channels, setChannels] = useState({ loading: false, results: [] });



  useEffect(() => {
    if (views !== screen) {
      const queryParams = new URLSearchParams(searchParams);
      queryParams.set("screen", views);
      setSearchParams(queryParams);
    }
    // eslint-disable-next-line
  }, [views]);

  useEffect(() => {
    if (sortField) {
      const queryParams = new URLSearchParams(searchParams);
      queryParams.set("orderBy", sortField);
      setSearchParams(queryParams);
    }
    // eslint-disable-next-line
  }, [sortField]);


  const handleDeleteYoutubeVideo = (row) => () => {
    selectRow(row);
    showDeleteYtDialog(true);
  }
  const handleStatusUpdate = (row) => () => {
    selectRow(row);
    showUpdateYtDialog(true);
  }
  const handlePublishingRetry = (row) => () => {
    selectRow(row);
    showRetryConfirmationDialog(true);
  }

  const FetchVideos = async () => {
    setLoading(true)
    const searchParams = {
      page: pagination.page + 1,
      page_size: pagination.rowPerPage,
      orderBy: sortField,
      queue_status: encodeURI(parameters),

    };
    if (filters.user) {
      searchParams.user = filters.user?.id
    }
    if (filters.channel) {
      searchParams.channel = filters.channel.channel_name
    }
    api
      .get("/youtube/list", { params: searchParams })
      .then((res) => {
        if (res.status === 200) {
          setVideos(res.data.results);
          setPagination((prev) => ({ ...prev, count: res.data.count }));
        }
      })
      .catch((error) => {
        console.error(error);
      }).finally(re => {
        setLoading(false)
      });
  }
  const FechChannels = async () => {
    setChannels(pre => ({ ...pre, loading: true }))
    api.get('/youtube/channels/view', {
      params: {
        page: 1,
        page_size: 50000
      }
    })
      .then(response => {
        if (response.status === 200) {
          const data = response.data?.results;
          setChannels({ loading: false, results: data });
        }
      })
      .catch(error => console.error('Error fetching channels:', error))
      .finally(() => {
        setChannels(pre => ({ ...pre, loading: false }))
      });
  }

  useEffect(() => {
    if (channels.results.length > 0) {
      if (channel) {
        const ch = channels.results.find(ch => ch.channel_name === channel);
        if (ch) {
          setFilters(pre => ({ ...pre, channel: ch }))
        }

      }
    }
  }, [channels.results])

  useEffect(() => {
    FechChannels();
  }, []);

  useEffect(() => {
    if (parameters !== null) {
      FetchVideos();
    }
    // eslint-disable-next-line
  }, [filters, pagination.page, pagination.rowPerPage, parameters, sortField]);

  useEffect(
    () => {
      let _params = [];
      switch (views) {
        case "completed":
          _params.push(THREAD_STATUS_ENUM.COMPLETED);
          break;
        case "others":
          _params.push(THREAD_STATUS_ENUM.FAILED);
          _params.push(THREAD_STATUS_ENUM.INQUEUE);
          _params.push(THREAD_STATUS_ENUM.PENDING);
          _params.push(THREAD_STATUS_ENUM.RUNNING);
          break;
        default:
          _params.push(THREAD_STATUS_ENUM.COMPLETED);
          break;
      }
      setParams(_params);
    },
    // eslint-disable-next-line
    [views]
  );

  useEffect(
    () => {
      if (filters.channel) {
        const queryParams = new URLSearchParams(searchParams);
        queryParams.set("channel", filters.channel?.channel_name);
        setSearchParams(queryParams);
      }
    },
    // eslint-disable-next-line
    [filters.channel, channel]
  );



  const YoutubeDeleteDialogBox = useCallback(() => {
    return (
      <ConfirmDeleteYoutubeVideoDialog
        open={deleteYtDialog}
        handleClose={() => showDeleteYtDialog(false)}
        row={selectedRow}
        reFreshFunc={FetchVideos}
      />
    )
  },
    // eslint-disable-next-line
    [deleteYtDialog, selectedRow]
  );
  const YoutubeUpdateVideoStatusDialogBox = useCallback(() => {
    return (
      <YoutubeUpdateVideoStatusDialog
        open={updateYtDialog}
        handleClose={() => showUpdateYtDialog(false)}
        row={selectedRow}
        reFreshFunc={FetchVideos}
      />
    )
  },
    // eslint-disable-next-line
    [updateYtDialog, selectedRow]
  );
  const YoutubePublishingRetryDialogBox = useCallback(() => {
    return (
      <YoutubePublishingRetryDialog
        open={retryConfirmationDialog}
        handleClose={() => showRetryConfirmationDialog(false)}
        row={selectedRow}
        reFreshFunc={FetchVideos}
      />
    )
  },
    // eslint-disable-next-line
    [retryConfirmationDialog, selectedRow]
  );

  const columns = [
    {
      field: "action", headerName: "", minWidth: 200, renderCell: (params) => {
        return <Box display="flex" width="100%" justifyContent="center" alignItems="center" gap={1}>
          {params.row?.youtube_video_id &&
            <Tooltip title="View on yoututbe">
              <Link target="_blank" href={`https://www.youtube.com/watch?v=${params.row?.youtube_video_id}`}><YouTubeIcon sx={{ color: "error.light" }} fontSize="small" /></Link>
            </Tooltip>
          }
          {params.row?.youtube_video_id &&
            <Tooltip title="Delete video from youtube">
              <IconButton onClick={handleDeleteYoutubeVideo(params.row)} >
                <Box position="relative" >
                  <YouTubeIcon sx={{ color: "error.light" }} fontSize="small" />
                  <DeleteForeverIcon sx={{ color: "error.dark", fontSize: 15, position: "absolute", left: 10, top: 10 }} color="error" />
                </Box>
              </IconButton>
            </Tooltip>
          }
          {params.row?.youtube_video_id &&
            <Tooltip title="Update youtube video status">
              <IconButton onClick={handleStatusUpdate(params.row)} >
                <Box position="relative" >
                  <YouTubeIcon sx={{ color: "error.light" }} fontSize="small" />
                  <EditIcon sx={{ color: "primary.dark", fontSize: 15, position: "absolute", left: 10, top: 10 }} color="error" />
                </Box>
              </IconButton>
            </Tooltip>
          }
          {params.row?.status[0].status === THREAD_STATUS_ENUM.FAILED &&
            <Tooltip title="Retry Publishing">
              <IconButton onClick={handlePublishingRetry(params.row)} >
                <Box position="relative" >
                  <YouTubeIcon sx={{ color: "error.light" }} fontSize="small" />
                  <RestartAltIcon sx={{ color: "error.dark", fontSize: 15, position: "absolute", left: 10, top: 10 }} color="error" />
                </Box>
              </IconButton>
            </Tooltip>
          }
        </Box>
      },
    },
    { field: "user", headerName: "User Id" },
    {
      field: "status",
      headerName: "Queue status",
      width: 120,
      renderCell: (params) => {
        return params.value[0]?.status;
      },
    },
    {
      field: "proccessstatus",
      headerName: "Proccess status",
      width: 300,
      renderCell: (params) => {
        return params.row?.status[0]?.procces_status;
      },
    },
    {
      field: "video_size",
      headerName: "Size (MB's)",
      minWidth: 150,
      type: "number",
      renderCell: (params) => {
        return params.value.toFixed(2);
      },
    },
    { field: "channel", headerName: "Channel", width: 200 },
    { field: "unique_key", headerName: "Key", width: 120 },
    { field: "title", headerName: "Title", width: 200 },
    { field: "created_date", headerName: "Created Date", width: 160, renderCell: (params) => new Date(params.value).toLocaleString() },
    { field: "uploaded_date", headerName: "Uploded Date", width: 160, renderCell: (params) => params.value ? new Date(params.value).toLocaleString() : "" },
    { field: "description", headerName: "Description", minWidth: 200 },
  ];

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" gap={2}>
      <Typography variant="h4" paragraph>
        Videos
      </Typography>
      <Box display="flex" gap={2.5}>
        <Button
          onClick={() => {
            setView("completed");
          }}
          sx={{
            borderRadius: 6,
            fontWeight: "bold",
            fontSize: views === "completed" ? 16 : 18,
            textTransform: "none",
            color: views === "completed" ? "white" : "grey",
            boxShadow:
              views === "completed" &&
              "0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgb(102, 115, 252)",
          }}
          size="large"
          variant={views === "completed" ? "contained" : "text"}
        >
          Completed
        </Button>
        <Button
          onClick={() => {
            setView("others");
          }}
          disableFocusRipple={views === "others"}
          sx={{
            borderRadius: 6,
            fontWeight: "bold",
            fontSize: views === "others" ? 16 : 18,
            textTransform: "none",
            color: views === "others" ? "white" : "grey",

            boxShadow:
              views === "others" &&
              "0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgb(102, 115, 252)",
          }}
          size="large"
          variant={views === "others" ? "contained" : "text"}
        >
          Others
        </Button>
      </Box>
      <Box display="flex" flexDirection="row" gap={2} justifyContent="space-between">
        <Box display="flex" flexDirection="row" gap={2} >
          <UserAutoComplete params={{ is_creator: true }} onChange={(_value) => { setFilters(pre => ({ ...pre, user: _value })); }} />
          {/* Channles Select */}
          <Autocomplete
            value={filters.channel}
            sx={{ minWidth: 250, maxWidth: 300 }}
            size="small"
            options={channels.results}
            getOptionLabel={(option) => option.channel_name}
            loading={channels.loading}
            onChange={(event, newValue) => {
              setFilters(pre => ({ ...pre, channel: newValue }));
              if (!newValue) {
                const queryParams = new URLSearchParams(searchParams);
                queryParams.delete("channel");
                setSearchParams(queryParams);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select a Channel"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />

          <Select
            value={sortField}
            onChange={(e) => setSortField(e.target.value)}
            displayEmpty
            size="small"
          >
            <MenuItem value="created_date"><Box display="flex" alignItems="center" gap={1}><ArrowUpwardIcon fontSize="small" />Created Date</Box></MenuItem>
            <MenuItem value="-created_date"><Box display="flex" alignItems="center" gap={1}><ArrowDownwardIcon fontSize="small" />Created Date</Box></MenuItem>
            <Divider variant="middle" />
            <MenuItem value="uploaded_date"><Box display="flex" alignItems="center" gap={1}><ArrowUpwardIcon fontSize="small" />Upload Date</Box></MenuItem>
            <MenuItem value="-uploaded_date"><Box display="flex" alignItems="center" gap={1}><ArrowDownwardIcon fontSize="small" />Upload Date</Box></MenuItem>
          </Select>


        </Box>

        <Tooltip title="Refresh Videos">
          <IconButton onClick={FetchVideos}>
            <SyncIcon color="info" />
          </IconButton>
        </Tooltip>
      </Box>
      <Scrollbars style={{ height: 800, width: "100%" }}>
        <Box component={Paper} borderRadius={4}>
          <DataGrid
            disableRowSelectionOnClick
            rows={videos}
            loading={loading}
            columns={views !== "others" ? columns : columns.filter(c => c.headerName !== "ID")}
            autoHeight
            columnHeaderHeight={38}
            paginationMode="server"
            paginationModel={{
              page: pagination.page,
              pageSize: pagination.rowPerPage,
            }}
            rowCount={pagination.count}
            pageSizeOptions={[10, 50, 100]}
            onPaginationModelChange={(newPage) => {
              const { page, pageSize } = newPage;
              setPagination((prev) => ({
                ...prev,
                page: page,
                rowPerPage: pageSize,
              }));
            }}
          />
        </Box>
      </Scrollbars>
      {/* Dialogs  */}
      <YoutubeDeleteDialogBox />
      <YoutubeUpdateVideoStatusDialogBox />
      <YoutubePublishingRetryDialogBox />
    </Box>
  );
}

export default VideosPreview;
