import { Box, List, Container, ListItem, Divider, Typography } from "@mui/material";
import React, { useState } from "react";

function TermsofServices() {

  return (
    <Container
      sx={{ textAlign: "justify" }}>
      <Box  >
        <Typography variant="h5" align="center" gutterBottom>
          <Box component="span" sx={{ color: "primary.main" }}> CreatorVault </Box> Agreement
        </Typography>
        <Typography variant="body1" paragraph>
          CreatorVault Licensing Agreement, (hereinafter referred to as the
          Agreement) is made and entered into by and between WooGlobe Ltd.
          (WooGlobe, CreatorVault, We, Licensee) doing business as
          CreatorVault and the undersigned producer/content owner
          (producer, you, your, Licensor). By registering for the
          service, the Licensor agrees to the terms of this Agreement, which
          becomes effective as of today's date (the Effective Date). This
          Agreement consists of the terms set forth below and Exhibit A, which
          is incorporated herein by reference.
        </Typography>

        <Typography variant="h6" gutterBottom>
          1. Licensed Rights
        </Typography>
        <Box p={2}>
          <Typography variant="body1">1. Licensor grants to Licensee, and Licensee accepts, subject to the terms of this Agreement, for the Licensed Material during the Term and throughout the Territory:</Typography>
          <Box p={2} display="flex" flexDirection="column" gap={1}>
            <Typography variant="body1">
              <strong>a)</strong>	worldwide, exclusive, sub-licensable, transferable, royalty-free and irrevocable license of the Licensed Material;
            </Typography>
            <Typography variant="body1">
              <strong>b)</strong>	the exclusive and unlimited right in perpetuity to:<br />
              <List>
                <ListItem>i.	manage, use, refrain from using, or alter the Licensed Material by any and all methods or means;</ListItem>
                <ListItem>ii.	allow others, by any and all methods or means, to exploit the Licensed Material by any and all methods or means, whether now known or hereafter devised, including without limitation in Media and throughout Distribution Channels, by itself, its successors or assigns, for any purpose whatsoever as Licensee in its sole discretion may determine;</ListItem>
                <ListItem>iii.	re-produce, distribute, modify, edit, adapt, publish, translate, incorporate, prepare derivative or collective works utilizing, display, and perform any portion of the Licensed Material including, but not limited to, in connection with the training or tuning of, ingestion into, analysis by, or any other manipulation of or use by, any machine-learning based assembly, large-language model, or other so-called artificial intelligence application or artificial intelligence engine (in each case whether or not generative, reactive, limited-memory, theory-of-mind, or self-aware, and whether or not image-, video-, or audio-based (diffusion-based or otherwise)), in each case for any purpose.</ListItem>
              </List>
            </Typography>
            <Typography variant="body1">
              <strong>c)</strong>	the exclusive right to irrevocably appoint Licensee as its attorney-in-fact to:<br />
              <List>
                <ListItem>i.	take any such action as may from time to time be necessary to effect, transfer, or assign the rights granted to Licensee herein, including without limitation copyright-related actions;</ListItem>
                <ListItem>ii.	enforce all claims and prosecute actions against any and all claims from the past, present, and future use of the Licensed Material by unauthorized third parties; and</ListItem>
                <ListItem>iii.	filing applications for registration of claims to copyright in the Licensed Material with the U.S. Copyright Office or any other foreign jurisdiction at such times as it deems necessary.</ListItem>
              </List>
            </Typography>
            <Typography variant="body1">
              <strong>d)</strong>	the non-exclusive worldwide right to use and publish and to permit others to use and publish Licensor's name and any other related third-party names, likeness, appearance, voice and biographical material, in any manner and in any medium solely in relation to the purposes of this Agreement.
            </Typography>
            <Typography variant="body1">
              <strong> e)</strong>	the sole right to prosecute, defend, settle and compromise all suits and actions respecting the Licensed Material and to do and perform all things necessary to prevent and restrain the infringement of copyright or other rights herein, and nothing herein shall obligate or authorize Licensee to institute any such proceedings.
            </Typography>

          </Box>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Typography variant="h6" gutterBottom>
          2. Payment to Licensor
        </Typography>
        <Box p={2}>
          <Typography variant="body1">
            1. In consideration of the rights granted herein by Licensor, Licensor shall be entitled to the following fee:
          </Typography>
          <Box p={2} display="flex" flexDirection="column" gap={1}>
            {/* a. Content Infringement Fees */}
            <Typography variant="body1">
              <strong>a)</strong> Content Infringement Fees:
            </Typography>
            <Box pl={2} display="flex" flexDirection="column" gap={1}>
              <Typography variant="body1">
                <strong>i.</strong> a fifty percent (50%) share of the net revenue actually earned and received by the Licensee from ad-revenues generated through automatic algorithmic claiming mechanisms (the “Automatic Infringement Fee”) on platforms that support such functionality and to which We have direct or indirect access, including through our partners. This includes but is not limited to YouTube Content ID, Meta Rights Management, and other rights management platforms, for all instances where the content has been used by an unaffiliated third party, without our prior authorization or permission.
              </Typography>
              <Typography variant="body1">
                <strong>ii.</strong> in the event that monies are actually received by the Licensee from the resolution of any manual copyright infringement claims relating to the Licensed Material, the Licensee shall pay the Licensor thirty percent (30%) of the net sums actually received by the Licensee for such resolution (the “Manual Infringement Fee”). Such Manual Infringement Fee shall be recovered through the use of manual copyright infringement collection efforts.
              </Typography>
            </Box>

            {/* b. Content Licensing Fees */}
            <Typography variant="body1">
              <strong>b)</strong> Content Licensing Fees:
            </Typography>
            <Box pl={2}>
              <Typography variant="body1">
                <strong>i.</strong> in the event that an unaffiliated third party specifically requests the use of the Licensed Material, and the Licensee, in its sole discretion, accepts such request, the Licensee shall pay the Licensor fifty percent (50%) of the fee obtained by the Licensee for such use (the “Third Party Fee”).
              </Typography>
            </Box>

            {/* c. Other Conditions */}
            <Typography variant="body1">
              <strong>c)</strong> Other Conditions:
            </Typography>
            <Box pl={2} display="flex" flexDirection="column" gap={1}>
              <Typography variant="body1">
                <strong>i.</strong> For purposes of clarification, all fees payable to the Licensor under this Agreement, including but not limited to the Automatic Infringement Fee, Manual Infringement Fee, and Third Party Fee, shall be subject to the deduction of reasonable costs incurred by the Licensee. Such costs may include, but are not limited to content storage, marketing, platform fees, reasonable outside attorneys’ fees, and all other related litigation, enforcement, administrative fees, or processing costs, as determined by the Licensee at its sole discretion. For the avoidance of doubt, any such deductions shall be made prior to our calculation of the revenue share.
              </Typography>
              <Typography variant="body1">
                <strong>ii.</strong> Conflict resolution fees will be charged when We handle a disputed asset resolution on Your behalf if you attempt to work with multiple content claiming providers at once, thus causing extra administrative work for Us. If in any given month there is one or more of these events, you will be charged an additional five percent of the partner ad-revenue generated in that given month.
              </Typography>
              <Typography variant="body1">
                <strong>iii.</strong> We are under no obligation to pay You for any re-uploaded videos that do not meet the content delivery requirements and criteria set by social media platforms including, but not limited to:
              </Typography>
              <Box pl={2} display="flex" flexDirection="column" gap={1}>
                <Typography variant="body1">
                  <strong>1.</strong> Content that falls below the minimum length required for algorithmic claiming; and
                </Typography>
                <Typography variant="body1">
                  <strong>2.</strong> Content that does not comply with the Community Guidelines or Content Monetization Guidelines established by the respective social media platforms.
                </Typography>
              </Box>
              <Typography variant="body1">
                <strong>iv.</strong> Licensor acknowledges and agrees that there will be no other residuals, revenue splits or payments of any kind due to Licensee in connection with this Agreement or for any uses set forth herein other than those specified in Sections 1.a.i, 1.a.ii and 1.b.i. For the avoidance of doubt, Licensor shall not be entitled to royalties, residuals, revenue splits or payments of any kind for the Licensed Material: (1) arising from any exploitation, distribution, or use by the Licensor, whether directly or indirectly, in any form or through any medium now known or hereafter devised; (2) on Licensor’s affiliates or partners’ website, channels and such affiliates/partners’ use on social media platforms; (3) via Licensor’s licensing agreements with third parties that allow such third party access to Licensor’s library.
              </Typography>
            </Box>

            {/* d. Payment Processing */}
            <Typography variant="body1">
              <strong>d)</strong> Payment Processing:
            </Typography>
            <Box pl={2} display="flex" flexDirection="column" gap={1}>
              <Typography variant="body1">
                <strong>i.</strong> The Fee shall be due to Licensor within thirty (30) days of receipt of the Third Party Fee.
              </Typography>
              <Typography variant="body1">
                <strong>ii.</strong> Licensor acknowledges and agrees that
              </Typography>
              <Box pl={2} display="flex" flexDirection="column" gap={1}>
                <Typography variant="body1">
                  <strong>1.</strong> Licensor is able to accept the fee set forth above in section 2(a) and 2(b) via PayPal.
                </Typography>
                <Typography variant="body1">
                  <strong>2.</strong> Licensee will only pay such fee to Licensor via PayPal; and
                </Typography>
                <Typography variant="body1">
                  <strong>3.</strong> Licensee is under no obligation to pay Licensor such fee via any other payment platform or payment method (unless otherwise agreed in writing between the parties).
                </Typography>
                <Typography variant="body1">
                  <strong>4.</strong> Any financial institution’s transaction fees, processing fees, and currency exchange fees, shall be borne solely by the Licensor.
                </Typography>
              </Box>
              <Typography variant="body1">
                <strong>iii.</strong> In the event that any portion of any Third-Party Fee or Infringement Fee owed to the Licensor is less than ten dollars ($10.00), such payment shall not accrue and shall not be paid and/or owed by the Licensee. For the avoidance of doubt, only payments that are equal to or greater than ten dollars ($10.00) shall be owed and paid to the Licensor.
              </Typography>
              <Typography variant="body1">
                <strong>iv.</strong> You will be responsible for taxes and reporting your revenues in your own respective country, and We will not be held liable in any way in the event you neglect your duties to report whatever ought to be reported.
              </Typography>
              <Typography variant="body1">
                <strong>v.</strong> The Licensor acknowledges and agrees that payments under this Agreement shall only be made when the total amount due to the Licensor reaches or exceeds one hundred dollars ($100.00). If the amount owed to the Licensor is less than this threshold, it shall be carried forward and accumulated until such time as the total payable amount meets or exceeds one hundred dollars ($100.00). No interest shall accrue on any withheld amounts. If the total accrued amount does not reach one hundred dollars ($100.00) within a period of one (1) year from the date the balance first began accumulating, the outstanding balance shall be deemed forfeited and applied toward the cost of services, administrative fees, or other expenses incurred by the Licensee, and the Licensor shall have no further claim to such amounts.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Typography variant="h6" gutterBottom>
          3. Licensor Warranties
        </Typography>
        <Box p={2}>
          <Typography variant="body1" paragraph>
            Licensor represents and warrants the following:
          </Typography>
          <Box pl={2} display="flex" flexDirection="column" gap={1}>
            <Typography variant="body1">
              <strong>1.</strong> Licensor has the full right, power and authority to enter into, fully perform, and grant the rights under Section 1.
            </Typography>
            <Typography variant="body1">
              <strong>2.</strong> Licensor hereby warrants and represents that Licensor is the sole and exclusive owner of the entire worldwide right, title and interest (including the copyrights
              and all property rights) in and to the Licensed Material.
            </Typography>
            <Typography variant="body1">
              <strong>3.</strong> Licensor has:
            </Typography>
            <Box pl={2} display="flex" flexDirection="column" gap={1}>
              <Typography variant="body1">
                <strong>i.</strong> obtained all consents, permissions, licenses, and clearances, including but not limited to any appearance and location releases, and paid all monies necessary for Licensee to exercise its exclusive rights hereunder, including but not limited to, the unlimited right for Licensee (and its licensees, successors, affiliates, parents, subsidiaries, and assigns) to use any individual’s, entity’s, or location’s name, appearance, voice, likeness, biographical information (as applicable) in connection with the Licensed Material, without any additional consents, permissions, licenses, and clearances required to be procured by Licensee;
              </Typography>
              <Typography variant="body1">
                <strong>ii.</strong> provided full disclosure and delivered to Licensee any and all relevant information in connection with individuals and/or entities in which Licensee has granted any type of right and/or permission to use the Licensed Material prior to the execution of this Agreement or will be provided shortly thereafter; and
              </Typography>
              <Typography variant="body1">
                <strong>iii.</strong> verified that there will not be any other rights to be cleared or any payments required to be made by Licensee as a result of any use of the Licensed Material pursuant to the rights and licenses herein granted to Licensee (including without limitation, payments in connection with other participations, agreements, and licensing rights).
              </Typography>
            </Box>
            <Typography variant="body1">
              <strong>4.</strong> Licensor has not entered into (and will not enter into), any other agreement in connection with the Licensed Material or otherwise, including but not limited to any non-exclusive or exclusive third party license agreements, and has not done or permitted (and will not do or permit) anything which may curtail or impair any of the rights granted to Licensee hereunder. In the event that Licensor receives any licensing requests in connection with the Licensed Material from a third party, Licensor shall immediately send such licensing request to Licensee at Licensing@WooGlobe.com. Licensor shall have the sole discretion to review and/or consider any such request.
            </Typography>
            <Typography variant="body1">
              <strong>5.</strong> All of the individuals and entities connected with the production of the Licensed Material, and all of the individuals and entities whose names, voices, photographs, likenesses, appearance, works, services and other materials appear or have been used in the Licensed Material, have authorized and approved Licensor’s use thereof and Licensee shall be free and clear to exploit the Licensed Material.
            </Typography>
            <Typography variant="body1">
              <strong>6.</strong> No part of the Licensed Material, any materials contained therein, or the exercise by Licensee of the Licensed Rights violates or will violate, or infringes or will infringe, any trademark, trade name, contract, agreement, copyright (whether common law or statutory), patent, literary, artistic, music, dramatic, personal, private, civil, property, privacy or publicity right or “moral rights of authors” or any other right of any person or entity, and shall not give rise to a claim of slander or libel. There are no existing, anticipated, or threatened claims or litigation that would adversely affect or impair any of the Licensed Rights.
            </Typography>
            <Typography variant="body1">
              <strong>7.</strong> No part of the Licensed Material or any materials contained therein, contain, were created or partially created by, or created with the assistance of, any machine-learning based assembly, large-language model, or other so-called artificial intelligence application or artificial intelligence engine (in each case whether or not generative, reactive, limited-memory, theory-of-mind, or self-aware, and whether or not image-, video-, or audio-based (diffusion-based or otherwise)).
            </Typography>
            <Typography variant="body1">
              <strong>8.</strong> Upon execution of this Agreement, Licensor agrees that it shall not: (i) have the authority to enforce any rights in connection with the Licensed Material, (ii) bring any claims or actions, and/or (iii) threaten to bring any claims or actions, against any third party for infringement of any rights in connection with the Licensed Material, including but not limited to, any copyright infringement claims or DMCA takedown requests. Licensor further agrees that any such claims, actions, or requests may only be brought by Licensee, in Licensee’s sole discretion. Licensor agrees and acknowledges that any claims, actions, or enforcement requests brought by Licensor against any third party for infringement of any rights in connection with the Licensed Material are unauthorized, shall be deemed a breach of this Agreement, and Licensor shall be liable for any and all expenses and/or damages arising out of such unauthorized claims or actions.
            </Typography>
            <Typography variant="body1">
              <strong>9.</strong> Licensor represents and warrants that Licensor has delivered any and all necessary documentation in order for Licensee to fulfill any and all payment obligations within a reasonable time following execution of this Agreement. Should Licensor fail to provide the required payment documentation, Licensee shall attempt to notify Licensor in writing (email to suffice) that the required information is missing. Licensor shall then have seventy-two (72) hours to cure the mistake by submitting the required information. In the event Licensor fails to timely or accurately deliver such information to Licensee, Licensor agrees and acknowledges that Licensee shall not be permitted to pay Licensor any fees owed herein and such payment shall not accrue.
            </Typography>
            <Typography variant="body1">
              <strong>10.</strong> Licensor agrees and acknowledges that any and all payment information provided to Licensee is accurate and reliable. In the event Licensee’s information is inaccurate and such payment transaction fails to process due to such mistake, Licensee shall attempt to remedy such transaction by notifying Licensor of such discrepancy. After such notification, if Licensor receives no response after fourteen (14) days following such notice or receives inaccurate information from Licensor following such notice, Licensor will not be paid any fees owed herein and such payment will not accrue.
            </Typography>
            <Typography variant="body1">
              <strong>11.</strong> Licensor represents and warrants that Licensor shall be solely responsible for any taxes Licensor owes directly or indirectly related to Licensor’s received payments. Licensee’s payments to Licensor are conditioned upon fulfillment of Licensor’s delivery obligations and receipt of Licensor’s completed U.S. Federal Tax form (e.g., W-9, W-8BEN, or W-8BEN-E), and any other tax documentation as may be required by law to carry out the intent of this Agreement.
            </Typography>
          </Box>
        </Box>


        <Divider sx={{ my: 2 }} />

        <Typography variant="h6" gutterBottom>
          4. Contract Term
        </Typography>

        <Box p={2}>
          <Typography variant="body1" paragraph>
            The term of this Agreement shall be twenty-four (24) months and shall automatically renew for successive twenty-four (24) month terms in perpetuity, unless You terminate this Agreement by providing no earlier than one hundred twenty (120) days and no later than ninety (90) days’ prior written notice before the renewal date. Termination cannot be requested before the initial twenty-four (24) month term has been completed. Such notice must be in the form of legal writing and sent to licensing@creatorvault.io.
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Typography variant="h6" gutterBottom>
          5. Termination
        </Typography>
        <Box p={2} display="flex" flexDirection="column" gap={1}>
          <Typography variant="body1">
            <strong>1.</strong> If You terminate this Agreement, We will refrain from managing any new content from You after the termination date. However, You acknowledge and agree that any rights already granted to WooGlobe, CreatorVault, or any of its authorized affiliates or partners prior to termination shall remain in full force and effect.
          </Typography>
          <Typography variant="body1">
            <strong>2.</strong> We shall retain exclusive, non-transferable license rights for all Content submitted prior to the effective date of termination. Any content, video assets, and intellectual property that You have licensed to Us during the term of this Agreement shall remain active, and the exclusive license granted hereunder shall survive termination in its entirety. Furthermore, we will continue to manage rights on the content on external rights management systems including but not limited to YouTube Content ID, Meta Rights Manager, and similar rights management tools.
          </Typography>
          <Typography variant="body1">
            <strong>3.</strong> Notwithstanding the foregoing, following the termination of this Agreement, You shall have the right to license the content previously submitted under this Agreement to third parties on a non-exclusive basis only.
          </Typography>
          <Typography variant="body1">
            <strong>4.</strong> We will continue to pay You any revenue share for the content licensed during the term of this Agreement, in accordance with the terms set forth herein.
          </Typography>
          <Typography variant="body1">
            <strong>5.</strong> We reserves the right to terminate this Agreement immediately and without any obligation to You in the event of any breach of its terms by You. Notice of any such breach and termination shall be provided to You in writing.
          </Typography>
          <Typography variant="body1">
            <strong>6.</strong> We reserve the right to terminate this Agreement at our sole discretion by providing You with at least 30 days’ prior written notice of the intended termination date. Such termination may occur in situations where it becomes technically or financially infeasible to continue providing services to You. However, other circumstances may also warrant termination, and the decision shall remain solely at our discretion. Upon receipt of such notice, both parties shall cooperate in good faith to conclude any outstanding obligations in an orderly manner. In the event of termination under this provision, neither party shall be entitled to any compensation, damages, or remedies, except for any outstanding payments due to You up to the effective date of termination. All content provided to us shall be fully released by us. However, any rights already granted to us or any of our authorized affiliates, partners, or third parties through us prior to termination shall remain in full force and effect. Termination of this Agreement shall not relieve either party of any obligations accrued prior to the effective date of termination.
          </Typography>
        </Box>
      </Box>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h6" gutterBottom>
        6. Miscellaneous
      </Typography>
      <Box p={2} display="flex" flexDirection="column" gap={1}>
        {/* 1. Indemnification */}
        <Typography variant="body1">
          <strong>1.</strong> Licensor hereby agrees to indemnify, release and hold harmless Licensee, its successors, licensees, sub distributors and assigns, and the directors, officers, employees, representatives and agents of each of the foregoing, from any and all claims, demands, causes of action, damages, judgments, liabilities, losses, costs, expenses, and attorney’s fees arising out of or resulting from:
        </Typography>
        <Box pl={2} display="flex" flexDirection="column" gap={1}>
          <Typography variant="body1">
            <strong>a)</strong> any breach by Licensor of any warranty, representation or any other provision of this Agreement; and/or
          </Typography>
          <Typography variant="body1">
            <strong>b)</strong> any claims of or respecting slander, libel, defamation, invasion of privacy or right of publicity, false light, infringement of copyright or trademark, or violations of any other rights arising out of or relating to any use by Licensee of the rights granted under this Agreement.
          </Typography>
        </Box>
        <Typography variant="body1">
          Licensor acknowledges that Licensee is relying on the representations made by Licensor in accordance with this Agreement and a breach by Licensor would cause Licensee irrevocable injury and damage that cannot be adequately compensated by damages in an action at law. Therefore, Licensor expressly agrees that, without limiting Licensee’s remedies, Licensee shall be entitled to injunctive and other equitable relief.
        </Typography>

        {/* 2. Confidentiality */}
        <Typography variant="body1">
          <strong>2.</strong> Licensor agrees to keep the agreement confidential. Licensor:
        </Typography>
        <Box pl={2} display="flex" flexDirection="column" gap={1}>
          <Typography variant="body1">
            <strong>a)</strong> shall not release or cause the release of any information concerning the Licensed Material, Licensee, or this Agreement without Licensee’s prior specific written consent (including, without limitation, posting, participating or engaging in social media discussions, news stories, blogs, reports or responses thereto);
          </Typography>
          <Typography variant="body1">
            <strong>b)</strong> shall direct all licensing or other inquiries relating to the Licensed Material solely to Licensee;
          </Typography>
          <Typography variant="body1">
            <strong>c)</strong> acknowledges that this Agreement is confidential in nature and agrees not to disclose the content or substance thereof to any third parties other than:
          </Typography>
          <Box pl={2} display="flex" flexDirection="column" gap={1}>
            <Typography variant="body1">
              <strong>i.</strong> Licensor’s respective attorneys and accountants; and/or
            </Typography>
            <Typography variant="body1">
              <strong>ii.</strong> as may be reasonably required in order to comply with any obligations imposed by this Agreement, or any statute, ordinance, rule, regulation, other law, or court order.
            </Typography>
          </Box>
        </Box>

        {/* 3. Entire Agreement and Modifications */}
        <Typography variant="body1">
          <strong>3.</strong> Licensor acknowledges and warrants that its license herein has not been induced by any representation or assurance not contained herein. This Agreement contains the entire understanding of the parties and shall not be modified or amended except by a written document executed by both parties. If any provision of this Agreement is found to be unlawful or unenforceable, such provision shall be limited only to the extent necessary, with all other provisions of this Agreement remaining in effect. The waiver by either party, or consent to a breach of any provision of this Agreement by the other party, shall not operate or be construed as a waiver of, consent to, or excuse of any other or subsequent breach by the other party. The headings or titles of the several paragraphs hereof are inserted solely for convenience and are not a part of, nor shall they be used or referred to in the construction of, any provision hereof. This Agreement:
        </Typography>
        <Box pl={2} display="flex" flexDirection="column" gap={1}>
          <Typography variant="body1">
            <strong>a)</strong> supersedes and replaces all prior agreements, negotiations or understandings in connection with the Licensed Material, including without limitation any simplified explanation of the terms herein, and in the event that there are any inconsistencies between this English-language contract and any translations of terms and conditions, the English-language version shall prevail; and
          </Typography>
          <Typography variant="body1">
            <strong>b)</strong> contains the entire understanding of the parties and shall not be modified or amended except by a written document executed by both parties.
          </Typography>
        </Box>

        {/* 4. Rights Management Tools */}
        <Typography variant="body1">
          <strong>4.</strong> Licensee acknowledges and agrees that We utilize platform-provided rights management tools, where available and accessible to us, to protect content across platforms such as Facebook, YouTube, TikTok, and others. These tools assist in identifying unauthorized use of content and, where possible, enable Us to claim and collect advertising revenue from monetized videos. Licensee further agrees that We shall not be held liable for any failure to identify, claim, or monetize unauthorized uses of the content, whether through automated tools or manual review. Licensee waives any claims or demands against WooGlobe for any missed enforcement actions, unclaimed revenues, or failure to detect unauthorized usage. Additionally, Licensee acknowledges that WooGlobe retains the sole discretion to remove videos where it deems strategic, including but not limited to cases involving repeat infringers.
        </Typography>

        {/* 5. Waiver of Moral Rights */}
        <Typography variant="body1">
          <strong>5.</strong> Licensor irrevocably waives, to the fullest extent permitted by applicable law, any and all moral rights, including but not limited to rights of attribution, integrity, modification, or any similar rights in connection with the Licensed Material. To the extent such rights are inalienable under applicable law, Licensor agrees not to assert, enforce, or authorize any claim based on moral rights against Licensee, its affiliates, sublicensees, successors, or assigns. Licensor further agrees that they shall not exercise moral rights in a manner that interferes with the full enjoyment of the rights granted under this Agreement.
        </Typography>

        {/* 6. Third Party Service Providers */}
        <Typography variant="body1">
          <strong>6.</strong> Licensor may, at its sole discretion, engage or subcontract any third party service providers, including but not limited to vendors, subcontractors, and other entities, to perform or assist in the delivery of the services under this Agreement. Such engagement shall not be deemed a breach of this Agreement, nor shall it relieve Licensor of its obligations hereunder.
        </Typography>

        {/* 7. Assignment */}
        <Typography variant="body1">
          <strong>7.</strong> Licensee shall have the right to assign freely the Licensed Material, this Agreement and/or any of Licensee’s other rights hereunder to any person or entity (by operation of law or otherwise). Licensor may not assign its rights hereunder.
        </Typography>

        {/* 8. Chatbot */}
        <Typography variant="body1">
          <strong>8.</strong> The Licensee may deploy a computer-based application, whether powered by artificial intelligence or other technologies, on its website and/or third-party messaging platforms to simulate or process human interactions (a “Chatbot”). This Chatbot enables the Licensor to engage with it via digital devices as though communicating with a real person. The Licensor acknowledges and agrees that the Chatbot may provide information relating to this Agreement, the Licensee’s services, and its offerings as a convenience and in support of the Licensee’s business operations. The Licensee accepts no liability or responsibility for any information, communications, or materials conveyed through the Chatbot, including but not limited to linked websites, embedded content, or references to third-party materials (collectively, “Chatbot Statements”). The Licensee makes no warranties or guarantees regarding the accuracy, reliability, or suitability of any Chatbot Statements for any specific purpose and shall not be held liable for any claims, damages, or losses arising from reliance on such Chatbot Statements.
        </Typography>

        {/* 9. Revenue and Exploitation */}
        <Typography variant="body1">
          <strong>9.</strong> Licensor acknowledges and agrees that WooGlobe is not making any representation, guarantee or agreement regarding the total amount of revenue, if any, which will be generated by the Licensed Rights. Licensor agrees that the judgment of WooGlobe regarding the exploitation of the Licensed Rights shall be binding and conclusive upon Licensor and agrees not to make any claim or action that WooGlobe has not properly exploited the Licensed Rights, that more revenue could have been earned than was actually earned by the exploitation of the Licensed Rights, or that any buyout or one-time payment to Licensor is insufficient in comparison to the revenue earned by the exploitation of the Licensed Rights. Nothing in this Agreement shall obligate WooGlobe to actually use or to exploit the Licensed Rights. WooGlobe is under no obligation to use, exploit, or monetize the Licensed Material on any platform. However, it may, at its sole discretion, sublicense the Licensed Material to third parties for revenue generation.
        </Typography>

        {/* 10. Governing Law and Dispute Resolution */}
        <Typography variant="body1">
          <strong>10.</strong> The parties agree that:
        </Typography>
        <Box pl={2} display="flex" flexDirection="column" gap={1}>
          <Typography variant="body1">
            <strong>a)</strong> this Agreement shall be governed by and construed in accordance with the laws of England and Wales.
          </Typography>
          <Typography variant="body1">
            <strong>b)</strong> any disputes, controversies, or claims arising out of or in connection with this Agreement shall be resolved through binding arbitration in London, United Kingdom, in accordance with the rules of the London Court of International Arbitration (LCIA). The arbitration shall be conducted in English, and any award shall be final and binding on the parties. Arbitration shall be limited to claims for actual monetary damages, if any.
          </Typography>
          <Typography variant="body1">
            <strong>c)</strong> the personal jurisdiction and venue to be England, UK and waive any objections to such jurisdiction or venue, regardless of whether a party is a resident of England, UK. Any disputes relating to this Agreement shall be subject to the non-exclusive jurisdiction of the courts of England, except for WooGlobe’s equitable rights as set forth herein.
          </Typography>
          <Typography variant="body1">
            <strong>d)</strong> In the event of any dispute, the Licensor shall not be entitled to, and hereby waives all rights to, any equitable relief whatsoever, including the right to rescind this Agreement, revoke any rights granted hereunder, or enjoin, restrain, or otherwise interfere with the marketing, advertisement, distribution, or exploitation of the Licensed Rights. Furthermore, the Licensor waives any rights to recover consequential, incidental, and/or punitive damages.
          </Typography>
        </Box>

        {/* 11. Attorneys’ Fees */}
        <Typography variant="body1">
          <strong>11.</strong> If either party initiates any legal action, lawsuit, or proceeding arising out of or related to this Agreement against the other, the prevailing party shall have the right to recover its reasonable attorneys’ fees incurred in connection with such action, in addition to any applicable litigation costs.
        </Typography>

        {/* 12. Notice Requirements */}
        <Typography variant="body1">
          <strong>12.</strong> Neither party shall be deemed in breach of its obligations under this Agreement unless and until it has received written notice from the other party specifying the nature of the breach or alleged breach in detail. The notified party shall have thirty (30) days from receipt of such notice to remedy the breach before any further action may be taken. All notices under this Agreement shall be delivered via confirmed facsimile, courier service, or certified mail with a return receipt requested. Notices shall be sent to the address specified in this Agreement or any updated address provided in writing by the receiving party.
        </Typography>

        {/* 13. Electronic Signature */}
        <Typography variant="body1">
          <strong>13.</strong> You agree that by entering your name into the space designated below or through the use of any electronic signature software/service or by any other means, you are agreeing to the terms of this agreement electronically. You agree that the electronic signature is the legal equivalent of a manual signature on this Agreement and that no certification authority or other third party verification is necessary to validate Licensor’s e-signature. The lack of such certification or third party verification will not in any way affect the enforceability of Your e-signature or any resulting contract between You and Us.
        </Typography>
      </Box>

    </Container >
  );
}

export default TermsofServices;

