import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  Autocomplete,
  Box,
  Chip,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import PhoneSelector from "../../../Components/Selectors/PhoneSelector";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { toast } from "react-toastify";
import api from "../../../conf/api";
import CountrySelect from "../../../Components/Selectors/CountrySelector";
import PasswordField from "../../../Components/Fields/PasswordField.js";
import TextInput from "../../../Components/Fields/TextInput.js";
import YouTubeIcon from '@mui/icons-material/YouTube';


export default function UserPopupBox({
  open,
  setOpen,
  registerUser,
  is_update,
  selectedRow,
  refreshFunc,
  is_promouser = false,
  is_staff = false,
  is_creator = false,
}) {
  const initialFormData = {
    first_name: "",
    last_name: "",
    email: "",
    username: "",
    phone_number: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",
    is_staff: is_staff,
    is_creator: is_creator,
    is_active: true,
    address: "",
    password: "",
    confirm_password: "",
    paypal_email: "",
    youtube: "",
    instagram: "",
    tiktok: "",
    gdrive: "",
    snapchat: "",
    promo_shares: 50,
    promo_youtube_links: [],
    is_promouser: is_promouser,
  };

  const [formData, setFormData] = React.useState(initialFormData);

  const handleClose = () => {
    setOpen(false);
    setFormData(formData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formData.is_promouser) {
      const isValid = formData.promo_youtube_links.length > 0;
      if (!isValid) {
        toast.error("One youtube channal is required.");
        return false;
      }
    }
    if (is_update) {
      const updateUSer = async () => {
        const _data = formData;
        delete _data["username"];
        delete _data["email"];
        _data["promo_youtube_links"] = formData.is_promouser ? JSON.stringify(formData.promo_youtube_links) : null
        _data["country"] = formData.country.label;
        await api
          .patch(`/users/update/${selectedRow}`, _data)
          .then((res) => {
            if (res.status === 200) {
              toast.success("User updated success.");
              refreshFunc();
              setFormData(formData);
              setOpen(false);
            }
          })
          .catch((res) => {
            // Handle the error
            toast.error("Error while uploading the video");
            console.log(res.response);
            try {
              const errorData = res.response.data;

              Object.keys(errorData).map((userKey) =>
                errorData[userKey].map((errorMessage, index) =>
                  toast.error(`${userKey}; ${errorMessage}`)
                )
              );
            } catch {
              console.log(res);
            } finally {
              setOpen(false);
              setFormData(initialFormData);
            }
          });
      };
      updateUSer();
    } else {
      if (formData.password !== formData.confirm_password) {
        toast.warning("Passwords do not match");
      } else if (formData.password.length < 8) {
        toast.warning("Password must be at least 8 characters long");
      } else {
        const data = formData;
        data["country"] = formData.country.label;
        data["promo_youtube_links"] = formData.is_promouser ? JSON.stringify(formData.promo_youtube_links) : null
        registerUser(data, () => {
          refreshFunc();
          setFormData(formData);
          setOpen(false);
        });
      }
    }
  };
  const handleYoutube = (e, value) => {
    if (!value) {
      setFormData(pre => ({ ...pre, promo_youtube_links: [] }));
      return
    }
    const pattern = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/@([a-zA-Z0-9_.-]+)$/;
    const urls = []
    for (const url of value) {
      if (pattern.test(url)) {
        urls.push(url)
      }
      else {
        toast.dismiss()
        toast.error("Youtube links are not in the correct format; they must be like `https://www.youtube.com/@username`");
      }
    }
    setFormData(pre => ({ ...pre, promo_youtube_links: urls }));
  }

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;

    if (
      (name === "is_promouser" && checked && formData.is_creator) ||
      (name === "is_creator" && checked && formData.is_promouser)
    ) {
      toast.warning("A user can either be a creator or a promo-user");
      setFormData((pre) => ({
        ...pre,
        is_promouser: name === "is_promouser" ? checked : false,
        is_creator: name === "is_creator" ? checked : false,
      }));
    } else {
      setFormData((pre) => ({
        ...pre,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  React.useEffect(() => {
    async function fetchData() {
      await api
        .get("/users/get/" + selectedRow)
        .then((res) => {
          const _data = res.data;
          _data["promo_youtube_links"] = _data["promo_youtube_links"] && JSON.parse(_data["promo_youtube_links"]);
          setFormData(_data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (is_update) {
      fetchData();
    }
    // eslint-disable-next-line
  }, []);


  const YoutubeUrlsAutoComplete = React.useCallback(() => {
    return <Autocomplete
      multiple
      size="large"
      options={[]}
      defaultValue={formData.promo_youtube_links}
      freeSolo
      onChange={handleYoutube}
      renderTags={(value, getTagProps) =>
        formData.promo_youtube_links?.map((link, index) => (
          <Chip variant="outlined" label={link} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{ ...params.InputProps, endAdornment: <InputAdornment position="end"><YouTubeIcon sx={{ color: "error.dark" }} /></InputAdornment> }}
          label="Youtube Channels"
          placeholder="https://www.youtube.com/@username"
        />
      )}
    />
  }, [formData.promo_youtube_links,])

  return (
    <Dialog maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle>{is_update ? "Update" : "Create"} User</DialogTitle>
      <DialogContent>
        <DialogContentText mb={2}>
          Please fill in the required information to{" "}
          {is_update ? "update" : "create"} a new user.
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <TextInput
                id="first_name"
                label="First Name"
                type="text"
                fullWidth
                required
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                id="last_name"
                label="Last Name"
                type="text"
                fullWidth
                required
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                disabled={is_update}
                id="username"
                label="Username"
                type="text"
                fullWidth
                required
                name="username"
                value={formData.username}
                onChange={handleChange}
              />
            </Grid>

            {is_promouser &&
              <Grid item xs={6}>
                <TextInput
                  id="promo_shares"
                  label="Revenue shares"
                  fullWidth
                  type="number"
                  required
                  name="promo_shares"
                  value={formData.promo_shares || 50}
                  onChange={handleChange}
                  InputProps={
                    {
                      endAdornment: (
                        <InputAdornment position="end">
                          <b>%</b>
                        </InputAdornment>
                      ),
                    }
                  }
                />
              </Grid>
            }
            <Grid item xs={12} md={6}>
              <TextInput
                id="email"
                disabled={is_update}
                label="Email address"
                type="email"
                fullWidth
                required
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                id="paypal_email"
                label="Paypal email address"
                type="email"
                fullWidth
                name="paypal_email"
                value={formData.paypal_email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CountrySelect
                required
                value={formData.country}
                onChange={(_value) => {
                  setFormData((pre) => ({ ...pre, country: _value }));
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PhoneSelector
                phone={formData.phone_number}
                setPhone={(phone) =>
                  setFormData({ ...formData, phone_number: phone })
                }
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextInput
                id="state"
                label="State"
                type="text"
                fullWidth
                name="state"
                value={formData.state}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextInput
                id="city"
                label="City"
                type="text"
                fullWidth
                name="city"
                value={formData.city}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInput
                label="Zip Code"
                type="text"
                fullWidth
                name="zipcode"
                value={formData.zipcode}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextInput
                minRows={3}
                id="address"
                multiline
                label="Address"
                type="text"
                fullWidth
                required
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
            </Grid>
            {is_promouser &&
              <Grid item xs={12}>
                <YoutubeUrlsAutoComplete />
              </Grid>
            }
            {!is_promouser &&
              <Grid item xs={12}>
                <TextInput
                  fullWidth
                  value={formData.youtube}
                  onChange={handleChange}
                  name={"youtube"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box
                          component="img"
                          width={20}
                          height={20}
                          src="https://cdn3.iconfinder.com/data/icons/social-network-30/512/social-06-512.png"
                        />
                      </InputAdornment>
                    ),
                  }}
                  label="Youtube link"
                  type="url"
                  variant="outlined"
                />
              </Grid>}
            {!is_promouser &&
              <Grid item xs={12}>
                <TextInput
                  fullWidth
                  required
                  value={formData.instagram}
                  onChange={handleChange}
                  name="instagram"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box
                          component="img"
                          width={20}
                          height={20}
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/600px-Instagram_icon.png"
                        />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Instagram link"
                  type="url"
                  variant="outlined"
                />
              </Grid>}
            {!is_promouser &&
              <Grid item xs={12}>
                <TextInput
                  fullWidth
                  required
                  value={formData.tiktok}
                  onChange={handleChange}
                  name="tiktok"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box
                          component="img"
                          width={20}
                          height={20}
                          src="https://w7.pngwing.com/pngs/814/840/png-transparent-tiktok-tiktok-logo-tiktok-icon-thumbnail.png"
                        />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Tiktok link"
                  type="url"
                  variant="outlined"
                />
              </Grid>}
            {!is_promouser &&
              <Grid item xs={12}>
                <TextInput
                  fullWidth
                  value={formData.gdrive}
                  onChange={handleChange}
                  name="gdrive"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box
                          component="img"
                          width={20}
                          height={20}
                          src="https://upload.wikimedia.org/wikipedia/commons/d/da/Google_Drive_logo.png"
                        />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Google Drive link"
                  type="url"
                  variant="outlined"
                />
              </Grid>}
            {!is_promouser &&
              <Grid item xs={12}>
                <TextInput
                  fullWidth
                  value={formData.snapchat}
                  onChange={handleChange}
                  name="snapchat"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box
                          component="img"
                          width={20}
                          height={20}
                          src="https://static.vecteezy.com/system/resources/previews/023/986/535/non_2x/snapchat-logo-snapchat-logo-transparent-snapchat-icon-transparent-free-free-png.png"
                        />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Snapchat link"
                  type="url"
                  variant="outlined"
                />
              </Grid>}
            {!is_update && (
              <Grid item xs={6}>
                <PasswordField
                  label="Password"
                  name="password"
                  fullWidth
                  required
                  value={formData.password}
                  onChange={handleChange}
                />
              </Grid>
            )}
            {!is_update && (
              <Grid item xs={6}>
                <PasswordField
                  label="Confirm Password"
                  name="confirm_password"
                  fullWidth
                  required
                  value={formData.confirm_password}
                  onChange={handleChange}
                />
              </Grid>
            )}
            <Grid
              container
              item
              sx={{ justifyContent: "space-between", padding: "16px 0 0 16px" }}
            >
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<CheckCircleOutlineIcon />}
                      checkedIcon={<CheckCircleIcon color="success" />}
                      checked={formData.is_staff}
                      name="is_staff"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  }
                  label="Admin"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<CheckCircleOutlineIcon />}
                      checkedIcon={<CheckCircleIcon color="success" />}
                      checked={formData.is_creator}
                      disabled={is_update}
                      name="is_creator"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  }
                  label="Creator"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<CheckCircleOutlineIcon />}
                      checkedIcon={<CheckCircleIcon color="success" />}
                      checked={formData.is_promouser}
                      disabled={is_update}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      name="is_promouser"
                    />
                  }
                  label="Promo User"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<CheckCircleOutlineIcon />}
                      checkedIcon={<CheckCircleIcon color="success" />}
                      checked={formData.is_active}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      name="is_active"
                    />
                  }
                  label="Active"
                />
              </Grid>
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" type="submit" color="primary">
              {is_update ? "Update" : "Create"} User
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}
